import React from "react"
import styled from "styled-components"

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`

const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const CloseButton = styled.button`
    background-color: #2222ff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
`


const Modal = ({ children }) => <ModalWrapper><ModalContent>{children}</ModalContent></ModalWrapper>

const InstructionModal = ({ show, onHide }) => {

    return (
        <Modal>
            <h1>Instructions</h1>
            <p>Your job is to draw greens. Not fairways, not teeboxes, not bunkers, but greens.</p>
            <p>Click the [DRAW] button below to start drawing a green. When you're done tapping around the green, click the button again to stop drawing, or merely close the shape on the map.</p>
            <p>When you run out of greens, hit the [NEW FACILITY] button to go to a new place, and keep drawing.</p>
            <p>Want to see your stats? Click on the [GREENS] count (upper left) to see your progress!</p>

            <p>Thanks for playing!</p>
            <p>~The Divvit Team</p>
            <button onClick={onHide}>Close</button>
        </Modal>
    )
}

export default InstructionModal