import { Box, Button, CardActions, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import booleanIntersects from '@turf/boolean-intersects'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FeatureTypes } from '../../featureTypes'
import { useVerifyTurkAuthOrRedirect } from '../../firebase'
import { olToGeojson, wktToFeature } from '../../utils'
import { smoothPolygon } from '../map/drawTools'
import Map from '../map/Map'
import { getLayer, useMap } from '../map/utils'
import DrawFooter from './components/DrawFooter'

import midDraw from '../../assets/demo.gif'
import normal from '../../assets/normal.png'
import par3 from '../../assets/par_3.png'
import dogleg from '../../assets/dogleg.png'
import bunker from '../../assets/bunker.png'
import preDrawn from '../../assets/existing.png'
import done from '../../assets/done.png'
import tee from '../../assets/tee.png'
// import intersect from '@turf/intersect'
// import area from '@turf/area'

const steps = [
    {
        title: 'It\'s our practice course where we bring you up to speed',
        description: 'Your job is to draw the greens on the course. You can draw a green by clicking a rough outline the map. Press "d" to start drawing a green, or click the `DRAW` button below. Doesn\'t look perfect? That\'s okay, the software will automatically smooth the green for you, so you don\'t need to be very precise.',
        image: midDraw
    },
    {
        title: 'Parts of a hole',
        description: 'A hole has a tee box, a green, and a fairway. Your only job is to draw greens, not teeboxes or fairways. You can draw a green by clicking a rough outline the map. Press "d" to start drawing a green, or click the `DRAW` button below. Doesn\'t look perfect? That\'s okay, the software will automatically smooth the green for you, so you don\'t need to be very precise.',
        image: normal
    },
    {
        title: 'These are teeboxes',
        description: 'Teeboxes are the starting point of a hole. There are generally multiple of these inline with one another. You don\'t need to draw them, so you can skip them. If you\'re not sure, skip it and move on.',
        image: tee
    },
    {
        title: 'Par 3 holes',
        description: 'Par 3 holes don\'t have a fairway, so it can be a little harder to tell where the green is. Try looking at nearby greens and teeboxes to help find the green. Usually, the green is the largest area of grass on the hole, and the green of one hole and the teebox of another are usually close together.',
        image: par3
    },
    {
        title: 'Doglegs',
        description: 'Doglegs are holes that curve to the left or right. The green will be at the end of the fairway, like normal, so don\'t let this confuse you. If you\'re not sure, skip it and move on.',
        image: dogleg
    },
    {
        title: 'Holes with bunkers',
        description: 'Some holes have bunkers, which are areas of sand, designed to make it harder to hit the ball. They are usually near greens and not teeboxes, so if you\'re confused which is which, that can be a good way to tell. If you\'re not sure, skip it and move on.',
        image: bunker
    },
    {
        title: 'Pre-Drawn Greens',
        description: 'Some holes have greens that are already drawn. There will be a white outline around all the shapes that exist. If you see a green that is already drawn, you can skip it and move on.',
        image: preDrawn
    },
    {
        title: 'That\'s it!',
        description: 'Try drawing 5 greens, and click the `DONE` button when you\'re done. Don\'t worry about quality, just try go get a feel for the app. These greens won\'t save, we have them already. This is a tutorial, to help you get a feel for the game. You can always come back to this tutorial if you need to.',
        image: done
    },
]



const tutorialCourseId = '43baaa0c-1c29-4a63-938f-58137640d6f6'

const DrawTutorial = () => {
    useVerifyTurkAuthOrRedirect()
    const map = useMap()
    const [stage, setStage] = useState(0)
    const [facility, setFacility] = useState()
    const [loadingFacility, setLoadingFacility] = useState(true)
    const [ greens, setGreens ] = useState([])
    const [ drawnGreens, setDrawnGreens ] = useState([])
    const [ activeStep, setActiveStep ] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        fetch(`https://atlas-api.services.divvit.co/courses/facility/${tutorialCourseId}`)
            .then(res => res.json())
            .then(res => {
                setFacility(res)
                setLoadingFacility(false)
            })
    }, [])

    useEffect(() => {
        if (map && facility) {
            const loc = facility?.location?.split('(')[1].split(')')[0].split(' ')
            if (loc && loc.length) {
                const long = loc[1]
                const lat = loc[0]

                fetch(`https://atlas-api.services.divvit.co/geometries/polygons/within/${lat}/${long}/0.05`).then(res => res.json()).then(data => {
                    setGreens(data)
                })
                map.getView().setZoom(16)
                map.getView().setCenter([-8930744.023342803, 3029551.7712076074])
            }

            const center = wktToFeature(facility.location, facility).getGeometry().getCoordinates()
            if (center) {
              map.getView().setCenter(center)
              map.getView().setZoom(16)
            }
        }
    }, [map, facility])

    const onDrawEnd = (feature) => {
        feature.set('type', FeatureTypes.GREEN)
        feature.setGeometry(smoothPolygon(feature).getGeometry())
        setDrawnGreens([...drawnGreens, feature])
        setTimeout(() => {
            getLayer(FeatureTypes.GREEN, map).getSource().addFeature(feature)
        }, 500)
    }

    const undoLastGreen = () => {
        const lastGreen = drawnGreens.pop()
        setDrawnGreens(drawnGreens)
        getLayer(FeatureTypes.GREEN, map).getSource().removeFeature(lastGreen)
    }

    const checkGreensForAccuracy = () => {
        // const geoJsonDrawnGreens = drawnGreens.map(olToGeojson)
        // const serverGreens = [...greens]
        // for (let i = 0; i < geoJsonDrawnGreens.length; i++) {
        //     const drawnGreen = geoJsonDrawnGreens[i]
        //     const correspondingGreen = serverGreens.find(serverGreen => booleanIntersects(serverGreen, drawnGreen))
        //     if (correspondingGreen) {
        //         const intersection = intersect(drawnGreen, correspondingGreen)
        //         const drawnGreenArea = area(drawnGreen)
        //         const intersectionArea = area(intersection)
        //         const percentCorrect = intersectionArea / drawnGreenArea
        //         const relativeToServerGreen = intersectionArea / area(correspondingGreen)
        //         if (percentCorrect < 0.9) {
        //             const badGreen = drawnGreens.splice(i, 1)[0]
        //             map.getView().fit(badGreen.getGeometry().getExtent())
        //             setDrawnGreens(drawnGreens)
        //             console.log("badGreen", badGreen)
        //             alert('You drew a green that is either too big or doesn\'t cover the green. Please try again.')
        //             getLayer(FeatureTypes.GREEN, map).getSource().removeFeature(badGreen)
        //             return
        //         } else if (relativeToServerGreen > 0.9 || percentCorrect > 1.1) {
        //             const badGreen = drawnGreens.splice(i, 1)[0]
        //             map.getView().fit(badGreen.getGeometry().getExtent())
        //             console.log("badGreen", badGreen)
        //             alert('You drew a green that is too small. Please try again.')
        //             setDrawnGreens(drawnGreens)
        //             getLayer(FeatureTypes.GREEN, map).getSource().removeFeature(badGreen)
        //             return
        //         }
        //     } else {
        //         const badGreen = drawnGreens.splice(i, 1)[0]
        //         setDrawnGreens(drawnGreens)
        //         map.getView().fit(badGreen.getGeometry().getExtent())
        //         alert('You drew a green that doesn\'t exist on the course. Please try again.')
        //         getLayer(FeatureTypes.GREEN, map).getSource().removeFeature(badGreen)
        //         return
        //     }
        // }
        alert('Thanks for playing the tutorial, now it\'s time to draw some real greens. Good luck!')
        localStorage.setItem('tutorialComplete', true)
        navigate('/turk')
    }

    useEffect(() => {
        if (activeStep === steps.length) {
            setStage(1)
        }
    }, [activeStep])

    return (
        <div>
            <Dialog onClose={() => setStage(1)} open={stage === 0}>
                <DialogTitle>👋 Welcome to Whitmoor Country Club</DialogTitle>
                <DialogContent>
                    <Box>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.title} title={step.title}>
                                    <StepLabel label={step.title}>{step.title}</StepLabel>
                                    <StepContent>
                                        <CardMedia>
                                            <img src={step.image} style={{ width: '100%', borderRadius: 5 }} />
                                        </CardMedia>
                                        <CardContent>
                                            <Typography>{step.description}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={() => setActiveStep(activeStep - 1)}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => setActiveStep(activeStep + 1)}
                                                    autoFocus
                                                >
                                                    {activeStep === steps.length - 1 ? 'Let me draw!' : 'Next'}
                                                </Button>
                                            </div>
                                        </CardActions>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </DialogContent>
            </Dialog>
            <Map style={{ height: '100vh', width: '100vw' }} />
            <DrawFooter onDrawEnd={onDrawEnd} disableUndo={drawnGreens.length === 0} undoLastGreen={undoLastGreen} />
            {drawnGreens.length > 3 && <Button variant='contained' style={{ position: 'absolute', right: 0, bottom: 0, margin: 20, zIndex: 150}} onClick={checkGreensForAccuracy}>DONE</Button>}
        </div>
    )
}

export default DrawTutorial
