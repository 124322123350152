import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import MapContext from "../components/map/MapContext"
import { geojsonToOl, wktToFeature } from "../utils"
import styled from "styled-components"
import DrawTools from "./components/DrawTools"
import { FeatureTypes } from "../featureTypes"
import FeatureTools from "./components/FeatureTools"
import InOrderMode from "./components/InOrderMode"
import { useVerifyAuthOrRedirect } from "../firebase"
import { getLayer } from "../components/map/utils"
import { courseEditLineStyle } from "../courseStyleFunc"
import ShapesLoading from "./components/ShapesLoading"
import { Dialog } from "@mui/material"

const SimpleButton = styled.span`
  border-radius: 4px;
  border: 1px solid #009E63;
  color: #009E63;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  cursor: pointer;
`

const ModeButton = styled.button`
    background: blue;
    color: white;
    border: 1px solid #000000;
    font-size: 1rem;
    border-radius: 5px;
    padding: 8px;
    margin: 5px;
`

const StopButton = styled.button`
    background: red;
    color: white;
    border: 1px solid #000000;
    font-size: 1rem;
    border-radius: 5px;
    padding: 8px;
    margin: 5px;
`

const Checkbox = styled.input`
  margin: 0 5px;
`

const EditCourse = () => {
  const { map } = useContext(MapContext)
  const params = useParams()
  const [facility, setFacility] = useState()
  const [course, setCourse] = useState()
  const [courseLoading, setCourseLoading] = useState(true)
  const [holeLines, setHoleLines] = useState([])
  const [greens, setPolygons] = useState([])
  const [holeLinesLoaded, setHoleLinesLoaded] = useState(false)
  const [polygonsLoaded, setPolygonsLoaded] = useState(false)
  const [inOrderMode, setInOrderMode] = useState(false)
  const [associateIntersecting, setAssociateIntersecting] = useState(true)
  const setReload = useState(false)[1]
  const [editScorecardModalOpen, setEditScorecardModalOpen] = useState(false)

  useVerifyAuthOrRedirect()

  console.log("course", course)

  const reloadState = () => setReload(r => !r)

  window.map = map

  const keyListener = (e) => {
    if (e.key === 'i') {
      setInOrderMode(!inOrderMode)
    } else if (e.key === 'r') {
      reloadState()
    }
  }

  const copyListener = (e) => {
    navigator.clipboard.writeText(`${facility?.name}${facility?.courses?.length > 1 ? ` - ${course?.name}` : ''} ${facility?.state_province ?? ''} golf pass`)
  }

  useEffect(() => {
    document.addEventListener('keydown', keyListener)

    return () => {
        document.removeEventListener('keydown', keyListener)
    }
    
}, [inOrderMode])

  useEffect(() => {
    (async () => {
      try {
        const [facility, course] = await Promise.all([
          fetch(`https://atlas-api.services.divvit.co/courses/facility/${params.facilityId}`).then(res => res.json()),
          fetch(`https://atlas-api.services.divvit.co/courses/course/${params.courseId}`).then(res => res.json()),
        ])
        setFacility(facility)
        setCourse(course)
        setCourseLoading(false)
      } catch (error) {
        console.error(error)
        setCourseLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    const loc = facility?.location?.split('(')[1].split(')')[0].split(' ')
    if (loc && loc.length) {
      const long = loc[1]
      const lat = loc[0]

      fetch(`https://atlas-api.services.divvit.co/geometries/lines/within/${lat}/${long}/0.05`).then(res => res.json()).then(data => {
        setHoleLines(data)
        setHoleLinesLoaded(true)
      })

      fetch(`https://atlas-api.services.divvit.co/geometries/polygons/within/${lat}/${long}/0.05`).then(res => res.json()).then(data => {
        setPolygons(data)
        setPolygonsLoaded(true)
      })
    }
    document.addEventListener('copy', copyListener)

    return () => {
      document.removeEventListener('copy', copyListener)
    }
  }, [facility])

  useEffect(() => {
    if (map && holeLinesLoaded) {
      getLayer(FeatureTypes.HOLE, map).getSource().clear()
      getLayer(FeatureTypes.HOLE, map).setStyle(courseEditLineStyle(course?.id))
      getLayer(FeatureTypes.HOLE, map).getSource().addFeatures(holeLines.map(line => geojsonToOl(line)))
    }
  }, [map, holeLines, holeLinesLoaded, course])

  useEffect(() => {
    if (map && polygonsLoaded) {
      getLayer(FeatureTypes.GREEN, map).getSource().clear()
      getLayer(FeatureTypes.GREEN, map).getSource().addFeatures(greens.filter(g => [FeatureTypes.WATER, FeatureTypes.GREEN].includes(g.properties.type)).map(green => geojsonToOl(green)))
    }
  }, [map, greens, polygonsLoaded])

  useEffect(() => {
    if (map && facility) {
      const center = wktToFeature(facility.location, facility).getGeometry().getCoordinates()
      if (center) {
        map.getView().animate({ center, zoom: 16, duration: 500 })
      }
    }
  }, [map, facility])

  const calculateCourseGreens = () => {
    const greens = getLayer(FeatureTypes.GREEN, map)?.getSource().getFeatures().filter(f => f.get('type') === FeatureTypes.GREEN) || []
    return greens.reduce((acc, green) => {
      if (green.get('associations')?.map(a => a?.course_id).includes(params.courseId)) {
        acc.inCourse.push(green)
      } else {
        acc.outOfCourse.push(green)
      }
      return acc
    }, {inCourse: [], outOfCourse: []})
  }

  const calculateCourseHoleLines = () => {
    const holeLines = getLayer(FeatureTypes.HOLE, map)?.getSource().getFeatures() || []
    return holeLines.reduce((acc, holeLine) => {
      if (holeLine.get('associations')?.map(a => a?.course_id).includes(params.courseId)) {
        acc.inCourse.push(holeLine)
      } else {
        acc.outOfCourse.push(holeLine)
      }
      return acc
    }, {inCourse: [], outOfCourse: []})
  }

  const updateHideCourse = async (hide_course) => {
    try {
      setCourseLoading(true)
      const course = await fetch(`https://atlas-api.services.divvit.co/courses/${params.courseId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({hide_course}),
      }).then(res => res.json())
      setCourse(course)
      setCourseLoading(false)
    }
    catch (error) {
      console.error(error)
      setCourseLoading(false)
    }
  }

  const unassociateAllInCourse = async () => {
    try {
      setCourseLoading(true)
      const greens = getLayer(FeatureTypes.GREEN, map)?.getSource().getFeatures() || []
      const holeLines = getLayer(FeatureTypes.HOLE, map)?.getSource().getFeatures() || []

      const greenAssociationIds = greens.filter(green => green.get('associations')?.map(a => a?.course_id).includes(params.courseId)).map(green => green.get('associations').find(a => a.course_id === params.courseId).id)
      const holeLineAssociationIds = holeLines.filter(holeLine => holeLine.get('associations')?.map(a => a?.course_id).includes(params.courseId)).map(holeLine => holeLine.get('associations').find(a => a.course_id === params.courseId).id)

      const greenPromises = greenAssociationIds.map(id => fetch(`https://atlas-api.services.divvit.co/associations/polygon/${id}`, {
        method: 'DELETE',
      }))
      const holeLinePromises = holeLineAssociationIds.map(id => fetch(`https://atlas-api.services.divvit.co/associations/line/${id}`, {
        method: 'DELETE',
      }))

      await Promise.all([...greenPromises, ...holeLinePromises])

      reloadState()
      const [facility, course] = await Promise.all([
        fetch(`https://atlas-api.services.divvit.co/courses/facility/${params.facilityId}`).then(res => res.json()),
        fetch(`https://atlas-api.services.divvit.co/courses/${params.courseId}`).then(res => res.json()),
      ])
      setFacility(facility)
      setCourse(course)
      setCourseLoading(false)
      reloadState()
    } catch (error) {
      console.error(error)
    }
  }


  return (
    <div>
      {!holeLinesLoaded || !polygonsLoaded ? <ShapesLoading greensLoading={!polygonsLoaded} holeLinesLoading={!holeLinesLoaded} /> : null}
      <FeatureTools courseId={course?.id} associateIntersecting={associateIntersecting} holeCount={course?.holes} rerenderPage={reloadState} />
      <div style={{ display: 'flex', fontWeight: 'bold', fontSize: 20, padding: 10 }} onClick={() => {navigator.clipboard.writeText(`${facility?.name}${facility?.courses?.length > 1 ? ` - ${course?.name}` : ''} ${facility?.state_province ?? ''} golf pass`)}}>
        {`${facility?.name}${facility?.courses?.length > 1 ? ` - ${course?.name}` : ''}`}
      </div>
      <DrawTools associateIntersecting={associateIntersecting} rerenderPage={reloadState} />

      <div style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
        <Checkbox type={'checkbox'} checked={associateIntersecting} onChange={() => setAssociateIntersecting(ai => !ai)} />
        <span onClick={() => setAssociateIntersecting(ai => !ai)}>Associate Intersecting Features</span>
      </div>

      <div>
        {!inOrderMode && (
          <ModeButton onClick={() => setInOrderMode(true)}>Start In-Order Mode [i]</ModeButton>
        )}
        {inOrderMode && (
          <>
            <InOrderMode rerenderPage={reloadState} associateIntersecting={associateIntersecting} course={course} quitMode={() => setInOrderMode(false)} />
            <StopButton onClick={() => setInOrderMode(false)}>Quit In-Order Mode [i]</StopButton>
          </>
        )}
      </div>
      <div style={{ padding: 5, display: 'flex' }}>
        <div style={{ border: '1px solid #3e98c7', borderRadius: 4, padding: 5, width: '50%' }}>
          <div>
            Hole Lines on the map:
          </div>
          <div>
            {calculateCourseHoleLines().inCourse.length} in the course
          </div>
          <div>
            {calculateCourseHoleLines().outOfCourse.length} out of the course
          </div>
        </div>
        <div style={{ border: '1px solid #3e98c7', borderRadius: 4, padding: 5, width: '50%' }}>
          <div>
            Greens on the map:
          </div>
          <div>
            {calculateCourseGreens().inCourse.length} in the course
          </div>
          <div>
            {calculateCourseGreens().outOfCourse.length} out of the course
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', padding: 5 }}>
        <Checkbox type={'checkbox'} disabled={courseLoading} checked={course?.hide_course} onChange={() => updateHideCourse(!course?.hide_course)} />
        <span onClick={() => !courseLoading && updateHideCourse(!course?.hide_course)}>Hide Course</span>
      </div>
      <SimpleButton onClick={() => reloadState()} style={{ marginTop: 10 }}>Reload from map</SimpleButton>
      <StopButton onDoubleClick={() => unassociateAllInCourse()}>Double Click to Unassociate All In Course</StopButton>
      <ModeButton onClick={() => setEditScorecardModalOpen(true)}>Edit Scorecard</ModeButton>

      <Dialog open={editScorecardModalOpen} onClose={() => setEditScorecardModalOpen(false)}>
        <div style={{ padding: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
            <div style={{ fontWeight: 'bold' }}>Hole</div>
            <div style={{ fontWeight: 'bold' }}>Par</div>
            <div style={{ fontWeight: 'bold' }}>Yards</div>
            <div style={{ fontWeight: 'bold' }}>Handicap</div>
          </div>
          {course?.scorecard?.map((hole, i) => (
            <div key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
              <div style={{ fontWeight: 'bold' }}>{i + 1}</div>
              <div style={{ fontWeight: 'bold' }}>{hole.par}</div>
              <div style={{ fontWeight: 'bold' }}>{hole.yards}</div>
              <div style={{ fontWeight: 'bold' }}>{hole.handicap}</div>
            </div>
          ))}
        </div>
      </Dialog>
    </div>
  )
}

export default EditCourse