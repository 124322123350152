import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const SearchBarWrapper = styled.form`
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  padding: 8px;
  border-radius: 2px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  background: #4caf50;
  color: #fff;
  border-radius: 2px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #3e8e41;
  }
`;

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    const [results, setResults] = useState([]);

    const handleChange = useCallback((event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);

        if (newSearchTerm === '') {
            setResults([]);
            return;
        }

        // clear any previous timeout
        clearTimeout(timeoutId);
        // create a new timeout
        const newTimeoutId = setTimeout(async () => {
            const response = await fetch(
                `https://atlas-api.services.divvit.co/courses/search/${newSearchTerm}`
            );
            const data = await response.json();
            setResults(data);
            // here you can call the function that will make the API call
        }, 500);
        setTimeoutId(newTimeoutId);
    }, [searchTerm, timeoutId]);

    const handleSubmit = event => {
        event.preventDefault();
    };

    return (
        <div>
            <SearchBarWrapper onSubmit={handleSubmit}>
                <SearchInput
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleChange}
                />
                <SearchButton type="submit">Search</SearchButton>
            </SearchBarWrapper>
            <div>
                {results.map((result) => (
                    <SearchResultItem key={result.id} {...result} />
                ))}
            </div>
        </div>
    );
}

export default SearchBar;


const ResultWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  transition: 0.2s;
  &:hover {
    background: #3e8e41;
  }
`;

const ResultName = styled.span`
  font-weight: 600;
  margin-right: 8px;
`;

const ResultCity = styled.span`
  font-weight: 400;
  margin-right: 8px;
`;

const ResultState = styled.span`
  font-weight: 400;
`;

const SearchResultItem = ({ name, city, state, id }) => {
    const navigate = useNavigate()
    return (
        <ResultWrapper onClick={() => navigate(`/edit/${id}`)}>
            <ResultName>{name}</ResultName>
            <ResultCity>{city},</ResultCity>
            <ResultState>{state}</ResultState>
        </ResultWrapper>
    );
}

