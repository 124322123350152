// index.js
import React from "react"
import { createRoot } from "react-dom/client"
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"
import styled from "styled-components"
import AdminRouter from "./components/admin/Router"
import Login from "./components/auth/Login"
import Register from "./components/auth/Register"
import Reset from "./components/auth/Reset"
import MapProvider from "./components/map/MapProvider"
import EndlessDraw from "./components/turk/EndlessDraw"
import DrawTutorial from "./components/turk/Tutorial"

const ModalWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`
const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Modal = ({ children }) => <ModalWrapper><ModalContent>{children}</ModalContent></ModalWrapper>

// render the app
const root = createRoot(document.getElementById("root"))
root.render(
  <MapProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/turk">
            <Route path="/turk/register" element={<Modal><Register /></Modal>} />
            <Route path="/turk/reset-password" element={<Modal><Reset /></Modal>} />
            <Route path="/turk/login" element={<Modal><Login /></Modal>} />
            <Route path="/turk/tutorial" element={<DrawTutorial />} />
            <Route path="/turk/:state/:count" element={<EndlessDraw />} />
            <Route path="/turk/:state" element={<EndlessDraw />} />
            <Route path="/turk" element={<EndlessDraw />} />
        </Route>
        <Route path="/*" index element={<AdminRouter />} />
      </Routes>
    </BrowserRouter>
  </MapProvider>
)
