import React, { useEffect } from 'react'
import { smoothPolygon, useDrawTools } from '../../components/map/drawTools'
import { FeatureTypes } from '../../featureTypes'
import { geojsonToOl, olToGeojson } from '../../utils'
import styled from "styled-components"
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import centerOfMass from "@turf/center-of-mass"
import { LineString } from 'ol/geom'
import { getUserId } from '../../firebase'
import { getLayer, useMap } from '../../components/map/utils'

const StopButton = styled.button`
    background: red;
    color: white;
    border: 1px solid #000000;
    font-size: 1rem;
    border-radius: 5px;
    padding: 8px;
    margin: 5px;
`

const DrawButton = styled.button`
    background: green;
    color: white;
    border: 1px solid #000000;
    font-size: 1rem;
    border-radius: 5px;
    padding: 8px;
    margin: 5px;
`

const DrawTools = () => {
    const drawTools = useDrawTools()
    const map = useMap()

    window.map = map

    const keyListener = (event) => {
        console.log(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);
        if (event.key == 'g' && !drawTools.currentDrawInteraction) {
            onStartGreen()
        } else if (event.key == 'h' && !drawTools.currentDrawInteraction) {
            onStartHoleLine()
        } else if (event.key == 'Escape' && drawTools.currentDrawInteraction) {
            drawTools.stop()
        } else if (event.key == 'Enter' && drawTools.currentDrawInteraction) {
            drawTools.currentDrawInteraction.finishDrawing()
        } else if (event.key == 'W' && !drawTools.currentDrawInteraction) {
            onStartWater()
        } else if (event.key == 't' && !drawTools.currentDrawInteraction) {
            onStartSmoothTeebox()
        } else if (event.key == 'b' && !drawTools.currentDrawInteraction) {
            onStartBunker()
        } else if (event.key == 'f' && !drawTools.currentDrawInteraction) {
            onStartFairway()
        } else if (event.key == 'T' && !drawTools.currentDrawInteraction) {
            onStartSquareTeebox()
        } else if (event.key == 'z') {
            map.getView().animate({
                zoom: map.getView().getZoom() + 1,
                duration: 100
            })
        } else if (event.key == 'x') {
            map.getView().animate({
                zoom: map.getView().getZoom() - 1,
                duration: 100
            })
        } else if (event.key == 'w') {
            var view = map.getView();
            var currentCenter = view.getCenter();
            var currentResolution = view.getResolution() * 200;
            var newCenter = [currentCenter[0], currentCenter[1] + currentResolution];
            view.animate({center: newCenter, duration: 100});        
        } else if (event.key == 's') {
            var view = map.getView();
            var currentCenter = view.getCenter();
            var currentResolution = view.getResolution() * 200;
            var newCenter = [currentCenter[0], currentCenter[1] - currentResolution];
            view.animate({center: newCenter, duration: 100});        
        } else if (event.key == 'a') {
            var view = map.getView();
            var currentCenter = view.getCenter();
            var currentResolution = view.getResolution() * 200;
            var newCenter = [currentCenter[0] - currentResolution, currentCenter[1]];
            view.animate({center: newCenter, duration: 100});        
        } else if (event.key == 'd') {
            var view = map.getView();
            var currentCenter = view.getCenter();
            var currentResolution = view.getResolution() * 200;
            var newCenter = [currentCenter[0] + currentResolution, currentCenter[1]];
            view.animate({center: newCenter, duration: 100});        
        }
    }

    useEffect(() => {
        if (map) {
            document.addEventListener('keydown', keyListener)
        }

        return () => {
            document.removeEventListener('keydown', keyListener)
        }
        
    }, [map, drawTools.currentDrawInteraction])

    const onStartHoleLine = () => {
        drawTools.startDraw(FeatureTypes.HOLE, async (feature) => {
            const greenLayer = map.getLayers().getArray().find(l => l.get('id') == FeatureTypes.GREEN)
            const green = greenLayer && greenLayer.getSource()?.getFeatures().filter(f => f.get('type') == FeatureTypes.GREEN).find(g => {
                return booleanPointInPolygon({type: "Feature", geometry: {type: "Point", coordinates: olToGeojson(feature).geometry.coordinates[olToGeojson(feature).geometry.coordinates.length - 1]}, properties: {}}, olToGeojson(g))
            })

            if (green) {
                const centerGreen = geojsonToOl(centerOfMass(olToGeojson(green)))
                const modifiedGeometry = feature.getGeometry().getCoordinates().map((c, i) => {
                    if (i == feature.getGeometry().getCoordinates().length - 1) {
                        return centerGreen.getGeometry().getCoordinates()
                    } else {
                        return c
                    }
                })
                feature.setGeometry(new LineString(modifiedGeometry))
            }


            const response = await fetch(`https://atlas-api.services.divvit.co/geometries/lines/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'Feature',
                    geometry: olToGeojson(feature).geometry,
                    properties: {
                        type: FeatureTypes.HOLE,
                        created_by: getUserId()
                    }
                })
            }).then(res => res.json())

            map.getLayers().getArray().find(l => l.get('id') == FeatureTypes.HOLE).getSource().addFeature(geojsonToOl(response))
        })
    }

    const onStartGreen = () => {
        drawTools.startDraw(FeatureTypes.GREEN, async (feature) => {
            const smoothed = smoothPolygon(feature)

            const response = await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'Feature',
                    geometry: olToGeojson(smoothed).geometry,
                    properties: {
                        type: FeatureTypes.GREEN,
                        created_by: getUserId()
                    }
                })
            }).then(res => res.json())

            map.getLayers().getArray().find(l => l.get('id') == FeatureTypes.GREEN).getSource().addFeature(geojsonToOl(response))
        })
    }

    const onStartWater = () => {
        drawTools.startDraw(FeatureTypes.WATER, async (feature) => {
            const smoothed = smoothPolygon(feature)

            const response = await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'Feature',
                    geometry: olToGeojson(smoothed).geometry,
                    properties: {
                        type: FeatureTypes.WATER,
                        created_by: getUserId()
                    }
                })
            }).then(res => res.json())

            getLayer(FeatureTypes.WATER, map).getSource().addFeature(geojsonToOl(response))
        })
    }

    const onStartSquareTeebox = () => {
        drawTools.startDraw(FeatureTypes.TEEBOX, async (feature) => {
            const response = await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'Feature',
                    geometry: olToGeojson(feature).geometry,
                    properties: {
                        type: FeatureTypes.TEEBOX,
                        created_by: getUserId()
                    }
                })
            }).then(res => res.json())

            getLayer(FeatureTypes.TEEBOX, map).getSource().addFeature(geojsonToOl(response))
        })
    }

    const onStartFairway = () => {
        drawTools.startDraw(FeatureTypes.FAIRWAY, async (feature) => {
            const response = await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'Feature',
                    geometry: olToGeojson(feature).geometry,
                    properties: {
                        type: FeatureTypes.FAIRWAY,
                        created_by: getUserId()
                    }
                })
            }).then(res => res.json())

            getLayer(FeatureTypes.FAIRWAY, map).getSource().addFeature(geojsonToOl(response))
        })
    }

    const onStartSmoothTeebox = () => {
        drawTools.startDraw(FeatureTypes.TEEBOX, async (feature) => {
            const smoothed = smoothPolygon(feature)

            const response = await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'Feature',
                    geometry: olToGeojson(smoothed).geometry,
                    properties: {
                        type: FeatureTypes.TEEBOX,
                        created_by: getUserId()
                    }
                })
            }).then(res => res.json())

            getLayer(FeatureTypes.TEEBOX, map).getSource().addFeature(geojsonToOl(response))
        })
    }

    const onStartBunker = () => {
        drawTools.startDraw(FeatureTypes.BUNKER, async (feature) => {
            const smoothed = smoothPolygon(feature)

            const response = await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    type: 'Feature',
                    geometry: olToGeojson(smoothed).geometry,
                    properties: {
                        type: FeatureTypes.BUNKER,
                        created_by: getUserId()
                    }
                })
            }).then(res => res.json())

            getLayer(FeatureTypes.BUNKER, map).getSource().addFeature(geojsonToOl(response))
        })
    }
    
    return (
        <div>
            {!drawTools.currentDrawInteraction ? <DrawButton onClick={onStartHoleLine}>Draw Hole Line [h]</DrawButton> : null}
            {!drawTools.currentDrawInteraction ? <DrawButton onClick={onStartGreen}>Draw Green [g]</DrawButton> : null}
            {drawTools.currentDrawInteraction ? <StopButton onClick={drawTools.stop}>Stop [esc]</StopButton> : null}
            {drawTools.currentDrawInteraction ? <DrawButton onClick={drawTools.currentDrawInteraction.finishDrawing}>Finish [enter]</DrawButton> : null}

            <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: '1000', borderRadius: 12, backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '1rem' }}>Controls:</div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Move: <code>wasd</code></div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Zoom: ZX</div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Hole Line: <code>h</code></div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Green: <code>g</code></div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Water: <code>W</code></div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Teebox: <code>t</code></div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Square Teebox: <code>T</code></div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Fairway: <code>f</code></div>
                <div style={{ padding: '5px 10px', color: 'white', fontSize: '0.6rem' }}>Bunker: <code>b</code></div>
            </div>
        </div>
    )
}

export default DrawTools