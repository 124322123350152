import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const CountContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 10px;
    margin: 20px;
`


const CountHeader = ({ count, onClick }) => {
    return (
       <CountContainer onClick={onClick}>
            <div>
                {count}
            </div>
            <div style={{ fontSize: 7 }}>
                GREENS
            </div>
        </CountContainer>
    )
}

export default CountHeader
