import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import MapContext from "./../components/map/MapContext"
import { useNavigate, useParams } from "react-router-dom"
import courseStyleFunc from "../courseStyleFunc.js"
import { wktToFeature, geojsonToOl, olToGeojson } from "../utils"
import { FeatureTypes } from "../featureTypes.js"
import VectorLayer from "ol/layer/Vector.js"
import VectorSource from "ol/source/Vector.js"
// import { PieChart } from 'react-minimal-pie-chart';
// import { Pie3D } from 'react-pie3d'
import { PieChart, PieArcSeries } from 'reaviz'
import DrawTools from "./components/DrawTools"
import { useVerifyAuthOrRedirect } from "../firebase"
import { getLayer } from "../components/map/utils"
import QRCode from "react-qr-code"
import ShapesLoading from "./components/ShapesLoading"
import Draw from "ol/interaction/Draw"


const EditWrapper = styled.div`
  margin: 0 15px;
`

const SimpleCard = styled.div`
  border-radius: 7px;
  border: 1px solid #efefef;
  padding: 15px;
  background-color: ${p => p.warning ? 'orange' : 'white'};
`

const CardTitle = styled.span`
  margin: 3px 0;
  font-weight: 600;
`

const Checkbox = styled.input`
  margin: 0 5px;
`

const SubmitButton = styled.button`
  background: #009E63;
  color: white;
  border: 1px solid #000000;
  font-size: 1rem;
  border-radius: 5px;
  padding: 8px;
  margin: 5px;
`

const CopyButton = styled.button`
  background: #009E63;
  color: white;
  border: 1px solid #000000;
  font-size: 1rem;
  border-radius: 5px;
  padding: 8px;
  margin: 5px;
`

const EditFacility = () => {
  const { map } = useContext(MapContext)
  const params = useParams()
  const [facility, setFacility] = useState({ courses: [] })
  const [facilityLoading, setFacilityLoading] = useState(true)
  const [holeLines, setHoleLines] = useState([])
  const [greens, setGreens] = useState([])
  const [holeLinesLoaded, setHoleLinesLoaded] = useState(false)
  const [greensLoaded, setGreensLoaded] = useState(false)
  const [editingName, setEditingName] = useState(false)
  const [nameChange, setNameChange] = useState(facility.name)
  const navigate = useNavigate()

  useVerifyAuthOrRedirect()

  useEffect(() => {
    setNameChange(facility.name)
  }, [facility.name])

  useEffect(() => {

    (async () => {
      try {
        const response = await fetch(`https://atlas-api.services.divvit.co/courses/facility/${params.facilityId}`).then(res => res.json())
        setFacility(response)
        setFacilityLoading(false)
        setNameChange(response.name)
      } catch (error) {
        console.log("error", error)
      }
    })()
  }, [])

  useEffect(() => {
    const loc = facility?.location?.split('(')[1].split(')')[0].split(' ')
    if (loc && loc.length) {
      const long = loc[1]
      const lat = loc[0]

      fetch(`https://atlas-api.services.divvit.co/geometries/lines/within/${lat}/${long}/0.05`).then(res => res.json()).then(data => {
        setHoleLines(data)
        setHoleLinesLoaded(true)
      })

      fetch(`https://atlas-api.services.divvit.co/geometries/polygons/within/${lat}/${long}/0.05`).then(res => res.json()).then(data => {
        setGreens(data)
        setGreensLoaded(true)
      })
    }
  }, [facility])

  useEffect(() => {
    if (map && holeLinesLoaded) {
      getLayer(FeatureTypes.HOLE, map).getSource()?.clear()
      getLayer(FeatureTypes.HOLE, map).getSource()?.addFeatures(holeLines.map(line => geojsonToOl(line)))
    }
  }, [map, holeLines, holeLinesLoaded])

  useEffect(() => {
    if (map && greensLoaded) {
      getLayer(FeatureTypes.GREEN, map).getSource()?.clear()
      getLayer(FeatureTypes.GREEN, map).getSource()?.addFeatures(greens.filter(feature => feature.properties.type.toLowerCase() === FeatureTypes.GREEN.toLowerCase()).map(green => geojsonToOl(green)))
    }
  }, [map, greens, greensLoaded])

  useEffect(() => {
    if (map && facility.courses.length) {
      const oldLayer = map.getLayers().getArray().find(layer => layer.get('id') === FeatureTypes.FACILITY)
      if (oldLayer) {
        map.removeLayer(oldLayer)
      }

      const center = wktToFeature(facility.location, facility).getGeometry().getCoordinates()
      if (center) {
        map.getView().animate({ center, zoom: 16, duration: 500 })
        // map.getView().setCenter(center, { duration: 100 })
        // map.getView().setZoom(16, { duration: 100 })
      }
    }
  }, [map, facility])

  const updateFacilityClosed = async (closed) => {
    try {
      setFacilityLoading(true)
      const response = await fetch(`https://atlas-api.services.divvit.co/courses/facility/${params.facilityId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ closed })
      }).then(res => res.json())
      setFacility(response)
      setFacilityLoading(false)
    } catch (error) {
      console.log("error", error)
      setFacilityLoading(false)
    }
  }

  const updateFacilityName = async () => {
    try {
      setEditingName(false)
      setFacilityLoading(true)
      const response = await fetch(`https://atlas-api.services.divvit.co/courses/facility/${params.facilityId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: nameChange })
      }).then(res => res.json())
      setFacility(response)
      setFacilityLoading(false)
      setNameChange(response.name)
    } catch (error) {
      console.log("error", error)
      setFacilityLoading(false)
    }
  }

  const handleFacilityLocationChange = async (olLocation) => {
    try {
      setFacilityLoading(true)
      const response = await fetch(`https://atlas-api.services.divvit.co/courses/facilities/${params.facilityId}/location`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ geometry: olToGeojson(olLocation).geometry })
      }).then(res => res.json())
      setFacility(response)
      setFacilityLoading(false)
    } catch (error) {
      console.log("error", error)
      setFacilityLoading(false)
    }
  }

  const startPointDraw = () => {
    if (map) {
      const draw = new Draw({
        source: getLayer(FeatureTypes.HOLE, map).getSource(),
        type: 'Point',
      })
      map.addInteraction(draw)
      draw.on('drawend', async (e) => {
        handleFacilityLocationChange(e.feature)
        map.removeInteraction(draw)
      })
    }
  }

  return (
    <EditWrapper>
      {!greensLoaded || !holeLinesLoaded ? <ShapesLoading greensLoading={!greensLoaded} holeLinesLoading={!holeLinesLoaded} /> : null}
      {!editingName ? <h3 onDoubleClick={() => setEditingName(true)}>{facility.name}</h3> : <><input type='text' value={nameChange} onSubmit={() => updateFacilityName()} onChange={e => setNameChange(e.target.value)} /><SubmitButton onClick={() => updateFacilityName()}>Submit</SubmitButton></>}
      <DrawTools />
      {facility ? (
        <div>
          <div style={{ paddingTop: 12, display: 'flex', alignContent: 'center' }}>
            <Checkbox disabled={facilityLoading} onChange={e => {
              updateFacilityClosed(e.target.checked)
            }} checked={facility.closed} type='checkbox' />
            <span onClick={() => !facilityLoading && updateFacilityClosed(!facility.closed)}>Facility Closed</span>
          </div>
          <div style={{ marginTop: 10 }}>
            <CopyButton onClick={() => navigate(`/turk?facilityId=${params.facilityId}`)}>Go to turk mode</CopyButton>
            <CopyButton onClick={() => window.navigator.clipboard.writeText(`https://atlas.services.divvit.co/turk?facilityId=${params.facilityId}`)}>Copy link to turk mode</CopyButton>
          </div>
          <div style={{ marginTop: 10 }}>
            <CopyButton onClick={() => startPointDraw()}>Change Location</CopyButton>
          </div>
        </div>)
        : null}
      <>
        {facility.courses.map(course => (
          <SimpleCard warning={course.hide_course} key={course.id} style={{ 'marginTop': '15px' }} onClick={() => navigate(`/edit/${params.facilityId}/${course.id}`, { state: { facility } })}>
            <CardTitle>{course.name}</CardTitle>
            <div style={{ display: 'flex', padding: 10 }}>
              <div style={{ flex: 1, padding: 8 }}>
                <p>Hole Lines</p>
                <PieChart
                  disabled={!holeLinesLoaded}
                  height={150}
                  width={150}
                  data={[
                    {key: `Missing`, data: course?.holes - course?.hole_count || 0, color: 'grey'},
                    {key: `Ok`, data: course?.hole_count || 0, color: 'green'},
                  ]}
                  series={
                    <PieArcSeries
                      cornerRadius={4}
                      padAngle={0.02}
                      padRadius={200}
                      doughnut={true}
                      displayAllLabels
                    />
                  }          
                />
              </div>
              <div style={{ flex: 1, padding: 8 }}>
                <p>Greens</p>
                <PieChart
                  disabled={!greensLoaded}
                  height={150}
                  width={150}
                  data={[
                    {key: `Missing`, data: course?.holes - course?.green_count || 0, color: 'grey'},
                    {key: `Ok`, data: course?.green_count || 0, color: 'green'},
                  ]}
                  series={
                    <PieArcSeries
                      cornerRadius={4}
                      padAngle={0.02}
                      padRadius={200}
                      doughnut={true}
                      displayAllLabels
                    />
                  }          
                />
              </div>
            </div>
          </SimpleCard>
        ))}
      </>
      <div>
        <QRCode value={`https://atlas.services.divvit.co/turk?facilityId=${params.facilityId}`} />
      </div>
    </EditWrapper>
  )
}

export default EditFacility