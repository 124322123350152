import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { useParams, useNavigate, useSearchParams } from "react-router-dom"
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { SpinnerInfinity } from 'spinners-react';
import MapContext from "../components/map/MapContext";
import { geojsonToOl, olToGeojson, wktToFeature } from "../utils";
import { FeatureTypes } from "../featureTypes.js"
import VectorLayer from "ol/layer/Vector.js"
import VectorSource from "ol/source/Vector.js"
import courseStyleFunc, { courseIdentifierFunc, facilityStyleFunc, labeledFacilityStyleFunc } from "../courseStyleFunc";
import { useVerifyAuthOrRedirect } from "../firebase";
import { VectorTile } from "ol/layer";
import { VectorTile as VectorTileSource } from 'ol/source'
import { MVT } from 'ol/format'
import convex from "@turf/convex";

const ListCard = styled.div`
  cursor: pointer;
  display: flex;
  margin: 17px;
  padding: 10px;
  background: ${p => p.closed ? '#aaaaaa' : p.needsAttention ? '#FF7900' : '#ffffff'};
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 2px 14px rgba(0,0,0,0.1),0 -1px 0px rgba(0,0,0,0.02);
`

const Select = styled.select`
  padding: 5px;
  margin: 17px;
  border-radius: 5px;
  border: 1px solid #3e98c7;
`

const Checkbox = styled.input`
  background: #3e98c7;
`

const Label = styled.label`
  margin: 0 5px;
  font-size: 14px;
`

const labelZoom = 10

class Sort {
  static byName(a, b) {
    return a.name.localeCompare(b.name)
  }
  static reverseByName(a, b) {
    return b.name.localeCompare(a.name)
  }
  static byCompletion(a, b) {
    const aCompletion = (a.courses?.filter(c => !c.hide_course).map(c => c.hole_count).reduce((acc, count) => acc + count, 0) || 0) / a.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0)
    const bCompletion = (b.courses?.filter(c => !c.hide_course).map(c => c.hole_count).reduce((acc, count) => acc + count, 0) || 0) / b.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0)
    return aCompletion - bCompletion
  }
  static reverseByCompletion(a, b) {
    const aCompletion = (a.courses?.filter(c => !c.hide_course).map(c => c.hole_count).reduce((acc, count) => acc + count, 0) || 0) / a.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0)
    const bCompletion = (b.courses?.filter(c => !c.hide_course).map(c => c.hole_count).reduce((acc, count) => acc + count, 0) || 0) / b.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0)
    return bCompletion - aCompletion
  }
}

const sortOptions = [
  { label: "Alphabetical", value: "name", sort: Sort.byName },
  { label: "Reverse Alphabetical", value: "reverseName", sort: Sort.reverseByName },
  { label: "Incomplete to Complete", value: "completion", sort: Sort.byCompletion },
  { label: "Complete to Incomplete", value: "reverseCompletion", sort: Sort.reverseByCompletion },
]

const polygonsVectorTileLayer = new VectorTile({
  source: new VectorTileSource({
      format: new MVT(),
      url: 'https://pg-tiles.services.divvit.co/public.polygon_features/{z}/{x}/{y}.pbf',
  }),
  style: courseStyleFunc()
})

const linesVectorTileLayer = new VectorTile({
  source: new VectorTileSource({
      format: new MVT(),
      url: 'https://pg-tiles.services.divvit.co/public.line_features/{z}/{x}/{y}.pbf',
  }),
  style: courseStyleFunc()
})

const FacilityList = (props) => {
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { map } = useContext(MapContext)
  const navigate = useNavigate()
  const [facilities, setFacilities] = useState([])
  const [sort, setSort] = useState(sortOptions.find(so => so.value == searchParams.get('sort')) || sortOptions[0])
  const [showClosed, setShowClosed] = useState(searchParams.get('showClosed') === 'true' || true)
  const [showCompleted, setShowCompleted] = useState(searchParams.get('showCompleted') === 'true' || true)
  const [showLines, setShowLines] = useState(false)
  const [showPolygons, setShowPolygons] = useState(false)

  useVerifyAuthOrRedirect()

  useEffect(() => {
    (async () => {
      fetch(`https://atlas-api.services.divvit.co/courses/facilities/extended/${params.state}`).then(r => r.json()).then(response => {
        response.sort(sort.sort)
        setFacilities(response)
      })
    })()
  }, [params.state])

  useEffect(() => {
    if (map) {
      map.on("moveend", mapMoveHandler)
      map.on("click", mapClickHandler)
    }
    return () => {
      if (map) {
        map.un("moveend", mapMoveHandler)
        map.un("click", mapClickHandler)
      }
    }
  }, [map])

  const mapMoveHandler = e => {
    const zoomLevel = e.map.getView().getZoom()
    const facilitiesLayer = e.map.getLayers().getArray().find(l => l.get("id") === FeatureTypes.FACILITY)
    if (facilitiesLayer && zoomLevel > labelZoom) {
      facilitiesLayer.setStyle(labeledFacilityStyleFunc)
    } else if (facilitiesLayer) {
      facilitiesLayer.setStyle(facilityStyleFunc)
    }
  }

  const mapClickHandler = e => {
    const features = e.map.getFeaturesAtPixel(e.pixel, { layerFilter: l => l.get("id") === FeatureTypes.FACILITY })
    if (features && features.length == 1) {
      const facilityId = features[0].get("id")
      navigate(`/edit/${facilityId}`)
    }
  }

  useEffect(() => {
    if (facilities.length !== 0 && map) {
      const oldLayer = map.getLayers().getArray().find(l => l.get("id") === FeatureTypes.FACILITY)
      if (oldLayer) {
        map.removeLayer(oldLayer)
      }

      const hull = convex({type: 'FeatureCollection', features: facilities.map(f => wktToFeature(f.location, f)).map(olToGeojson)})
      console.log("hull", hull)
      map.getView().fit(geojsonToOl(hull).getGeometry().getExtent(), { padding: [50, 50, 50, 50], constrainResolution: false, duration: 500 })

      const olFeatures = facilities.map(f => wktToFeature(f.location, f))
      const layer = new VectorLayer({
        source: new VectorSource({
          features: olFeatures,
        }),
        style: map.getView().getZoom() > labelZoom ? labeledFacilityStyleFunc : facilityStyleFunc,
      })
      layer.set("name", FeatureTypes.FACILITY)
      layer.set("id", FeatureTypes.FACILITY)
      map.addLayer(layer)
    }
  }, [facilities, map])

  const openDetailPanel = id => {
    try {
      navigate(`/edit/${id}`)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSortChange = e => {
    const selected = sortOptions.find(o => o.value === e.target.value)
    setSort(selected)
    setFacilities(fs => fs.sort(selected.sort))
    setSearchParams({ ...getParams(), sort: selected.value })
  }

  const handleHideClosed = e => {
    setShowClosed(e.target.checked)
    setSearchParams({ ...getParams(), showClosed: `${e.target.checked}` })
  }

  const handleHideCompleted = e => {
    setShowCompleted(e.target.checked)
    setSearchParams({ ...getParams(), showCompleted: `${e.target.checked}` })
  }

  const handleShowLines = e => {
    setShowLines(e.target.checked)
    if (e.target.checked) {
      map.addLayer(linesVectorTileLayer)
    } else {
      map.removeLayer(linesVectorTileLayer)
    }
  }

  const handleShowPolygons = e => {
    setShowPolygons(e.target.checked)
    if (e.target.checked) {
      map.addLayer(polygonsVectorTileLayer)
    } else {
      map.removeLayer(polygonsVectorTileLayer)
    }
  }

  const getParams = () => {
    return {
      showClosed: showClosed,
      showCompleted: showCompleted,
      sort: sort.value
    }
  }

  const validFacilities = facilities?.filter(f => f.courses?.length > 0 && !f.closed) || []
  const completedFacilities = validFacilities?.filter(f => f.courses?.filter(c => !c.hide_course).filter(c => c.holes == c.hole_count).length > 0)

  const totalCourses = validFacilities?.reduce((acc, f) => [...acc, ...(f.courses?.filter(c => !c.hide_course) || [])], [])
  const completedCourses = validFacilities?.reduce((acc, f) => [...acc, ...(f.courses?.filter(c => !c.hide_course).filter(c => c.holes == c.hole_count) || [])], [])

  const completedHoleLineCount = completedCourses.reduce((acc, c) => acc + c.hole_count, 0)
  const completedGreenCount = completedCourses.reduce((acc, c) => acc + c.green_count, 0)
  const totalHolesCount = totalCourses.reduce((acc, c) => acc + c.holes, 0)

  const completionPercent = Math.round(completedHoleLineCount * 1000 / totalHolesCount) / 10


  return (
    <div>
      {/* <Leaderboard /> */}
      {facilities.length === 0 ? <div style={{ paddingTop: 50, width: '100%', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><SpinnerInfinity size={120} /></div> : null}
      {facilities.length !== 0 ? <div style={{ paddingTop: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <h1>{params.state}</h1>
        <div style={{ width: '100%', height: 120, display: 'flex', justifyContent: 'space-evenly', padding: 10 }}>
          <div style={{ height: 100, width: 100 }}>
            <CircularProgressbar
              maxValue={1}
              value={completionPercent / 100}
              text={`${completionPercent}%`}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%' }}>
            <div>
              {completedFacilities.length}/{validFacilities.length} facilities ({Math.round(completedFacilities.length * 1000 / validFacilities.length) / 10}%)
            </div>
            <div>
              {completedCourses.length}/{totalCourses.length} courses ({Math.round(completedCourses.length * 1000 / totalCourses.length) / 10}%)
            </div>
            <div>
              {completedHoleLineCount}/{totalHolesCount} holes ({Math.round(completedHoleLineCount * 1000 / totalHolesCount) / 10}%)
            </div>
            <div>
              {completedGreenCount}/{totalHolesCount} greens ({Math.round(completedGreenCount * 1000 / totalHolesCount) / 10}%)
            </div>
          </div>
        </div>
        <ListCard style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 80px)', padding: 20}}>
          <div style={{ display: 'flex', justifyContent: 'left', width: '100%'}}>
            <Checkbox type="checkbox" checked={showLines} onChange={handleShowLines} /><span style={{ marginLeft: 10 }} onClick={() => handleShowLines({target: {checked: !showLines }})}> Show Lines Layer</span>
          </div>
          <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
            <Checkbox type="checkbox" checked={showPolygons} onChange={handleShowPolygons} /><span style={{ marginLeft: 10 }} onClick={() => handleShowPolygons({target: {checked: !showPolygons }})}> Show Polygons Layer</span>
          </div>
        </ListCard>
      </div> : null}
      {facilities.length ? <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ marginLeft: 10 }}><Checkbox type="checkbox" checked={showClosed} onChange={handleHideClosed} /><span onClick={() => handleHideClosed({target: {checked: !showClosed }})}> Show closed facilities</span></div>
        <div style={{ marginLeft: 10 }}><Checkbox type="checkbox" checked={showCompleted} onChange={handleHideCompleted} /><span onClick={() => handleHideCompleted({target: {checked: !showCompleted }})}> Show completed facilities</span></div>
        <Select
          value={sort.value}
          onChange={handleSortChange}
        >
          {sortOptions.map(s => (
            <option key={s.label} value={s.value}>{s.label}</option>
          ))}
        </Select>
      </div> : null}
      {facilities.filter(f => showClosed ? true : !f.closed).filter(f => showCompleted ? true : f.courses?.filter(c => !c.hide_course).map(c => c.hole_count).reduce((acc, count) => acc + count, 0) / f.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0) != 1 || f.courses?.filter(c => !c.hide_course).map(c => c.green_count).reduce((acc, count) => acc + count, 0) / f.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0) < 1).map(f => (
        <ListCard closed={f.closed} needsAttention={f.courses?.some(c => c.hide_course)} key={f.id} onClick={() => openDetailPanel(f.id)}>
          <span>{f.name} </span>
          <div style={{ height: 30, width: 50 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox disabled type="checkbox" checked={f.courses?.filter(c => !c.hide_course).map(c => c.hole_count).reduce((acc, count) => acc + count, 0) / f.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0) == 1} />
              <Label>HL</Label>
            </div>
            <div>
              <Checkbox disabled type="checkbox" checked={f.courses?.filter(c => !c.hide_course).map(c => c.green_count).reduce((acc, count) => acc + count, 0) / f.courses?.filter(c => !c.hide_course).map(c => c.holes).reduce((acc, count) => acc + count, 0) >= 1} />
              <Label>G</Label>
            </div>
            {/* <CircularProgressbar maxValue={1} value={f.courses?.map(c => c.hole_count).reduce((acc, count) => acc + count, 0) / f.courses?.map(c => c.holes).reduce((acc, count) => acc + count)} text={`${Math.round(f.courses?.map(c => c.hole_count).reduce((acc, count) => acc + count, 0) * 1000 / f.courses?.map(c => c.holes).reduce((acc, count) => acc + count)) / 10}%`} /> */}
          </div>
        </ListCard>
      ))}
    </div>
  )
}

export default FacilityList