import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import GeoJSON from 'ol/format/GeoJSON'
import React, { useEffect, useState } from "react"
import { CircularProgressbar } from "react-circular-progressbar"
import { useNavigate } from "react-router-dom"
import { SpinnerCircular } from "spinners-react"
import styled from "styled-components"
import { useMap } from "../components/map/utils"
import { useVerifyAuthOrRedirect, verifyAuthOrRedirect } from "../firebase"
import SearchBar from "./components/Search"
import { geojsonToOl } from "../utils"
import { KML } from "ol/format"
import Style from "ol/style/Style"
import Stroke from "ol/style/Stroke"
import Fill from "ol/style/Fill"

const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
]

const ListCard = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 17px;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 14px rgba(0,0,0,0.1),0 -1px 0px rgba(0,0,0,0.02);
`

const StatesList = () => {
    const navigate = useNavigate()
    const map = useMap()
    const [states, setStates] = useState();

    useVerifyAuthOrRedirect()

    const mapClickHandler = (e) => {
        const feature = map.forEachFeatureAtPixel(e.pixel, (feature) => feature)
        if (!feature) return
        map.removeLayer(map.getLayers().getArray().find(l => l.get('id') === 'STATES'))
        const state = states.find(s => s.state_province === feature.get('name'))
        navigate(`/${state.state_province}`)
    }

    useEffect(() => {
        if (!map) return
        map.on('click', mapClickHandler)
        return () => map.un('click', mapClickHandler)
    }, [map, states])

    useEffect(() => {
        if (!map) return
        (async () => {
            const response = await fetch('https://atlas-api.services.divvit.co/courses/states/extended').then(r => r.json())
            response.sort((a, b) => a.state_province.localeCompare(b.state_province))
            setStates(response)

            const newLayer = new VectorLayer({
                source: new VectorSource({
                    url: 'https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json',
                    format: new GeoJSON(),
                })
            })
            newLayer.set('id', 'STATES')
            newLayer.setStyle(feature => {
                return new Style({
                    stroke: new Stroke({
                        color: '#00000000',
                        width: 1,
                        }),
                    fill: new Fill({
                        color: '#ffffff00',
                    })
                })
            })
            map.addLayer(newLayer)

            setTimeout(() => {
                newLayer.getSource().getFeatures().forEach(feature => {
                    const state = response.find(s => s.state_province === feature.get('name'))

                    if (state) {
                        const courses = state.courses.filter(course => !course.hide_course && !course.closed)
                        const completedCourses = courses.filter(course => course.holes === course.hole_count)

                        const completedHoleLineCount = completedCourses.reduce((acc, course) => {
                            return acc + course.hole_count
                        }, 0)
                        const stateCompletedGreenCount = completedCourses.reduce((acc, course) => {
                            return acc + course.green_count
                        }, 0)
                        const stateHoleLineCount = courses.reduce((acc, course) => {
                            return acc + course.holes
                        }, 0)
                        const stateCompletionPercent = Math.round(completedHoleLineCount * 1000 / totalHoleLineCount) / 10

                        feature.setProperties({
                            completedCourses: completedCourses.length,
                            totalCourses: courses.length,
                            completedHoleLineCount,
                            stateHoleLineCount,
                            stateCompletedGreenCount,
                            stateCompletionPercent,
                        })
                    }
                })

                newLayer.setStyle(feature => {
                    if (feature.get('completedCourses') === feature.get('totalCourses')) {
                        return new Style({
                            stroke: new Stroke({
                                color: '#000000',
                                width: 1,
                            }),
                            fill: new Fill({
                                color: '#00ff0055',
                            })
                        })
                    }
                    const percent = feature.get('completedHoleLineCount') * 100 / feature.get('stateHoleLineCount')
                    const color = `rgba(${Math.round(255 - percent * 2.55)}, ${Math.round(percent * 2.55)}, 0, ${percent / 150 + 0.25})`
                    console.log("state", feature.get('name'), "percent", percent, "color", color)
                    return new Style({
                        stroke: new Stroke({
                            color: '#000000',
                            width: 1,
                        }),
                        fill: new Fill({
                            color: color,
                        })
                    })
                })
            }, 2000)
        })()
    }, [map])

    useEffect(() => {
        if (map) {
            const usBounds = {
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "coordinates": [
                    [
                      [
                        -127.35401372190313,
                        49.010444808722326
                      ],
                      [
                        -127.35401372190313,
                        24.86796031157266
                      ],
                      [
                        -66.58023941084353,
                        24.86796031157266
                      ],
                      [
                        -66.58023941084353,
                        49.010444808722326
                      ],
                      [
                        -127.35401372190313,
                        49.010444808722326
                      ]
                    ]
                  ],
                  "type": "Polygon"
                }
              }
            map.getView().fit(geojsonToOl(usBounds).getGeometry().getExtent(), { duration: 800 })
        }
    }, [map])

    window.map = map

    // useEffect(() => {
    //     if (map && states) {
    //         (async () => {
    //             const geojson = await fetch('https://eric.clst.org/assets/wiki/uploads/Stuff/gz_2010_us_040_00_500k').then(r => r.json())
    //             const alteredFeatures = geojson.features.map(feature => {
    //                 const state = states.find(s => s.state_province === feature.properties.NAME)
    //                 const courses = state.courses.filter(course => !course.hide_course && !course.closed)
    //                 const completedCourses = courses.filter(course => course.holes === course.hole_count)
    //                 if (state) {
    //                     feature.properties = {
    //                         ...feature.properties,
    //                         completedCourses: completedCourses.length,
    //                         totalCourses: courses.length,
    //                         isCompleted: completedCourses.length === courses.length,
    //                     }
    //                 }
    //                 return feature
    //             })
    //             map.addLayer(new VectorLayer({
    //                 source: new VectorSource({
    //                     features: (new GeoJSON()).readFeatures({
    //                         type: 'FeatureCollection',
    //                         features: alteredFeatures,
    //                     }),
    //                 })
    //             }))
    //         })()
    //     }
    // }, [map, states])

    const handleClick = (state) => {
        navigate(`/${state}`)
    }

    const totalCourses = (states?.reduce((acc, state) => [...acc, ...state.courses], []) || []).filter(c => !c.hide_course && !c.closed)
    const totalHoleLineCount = totalCourses?.reduce((acc, course) => acc + course.holes, 0)
    const completedHoleLines = totalCourses?.reduce((acc, course) => acc + course.hole_count, 0)
    const completedGreens = totalCourses?.reduce((acc, course) => acc + course.green_count, 0)
    const totalCompletedCourses = totalCourses?.filter(course => course.holes === course.hole_count)
    const completionPercent = Math.round(totalCompletedCourses.length * 10000 / totalCourses.length) / 100
    const completedStates = states?.filter(state => state.courses.filter(course => !course.hide_course && !course.closed).length === state.courses.filter(course => !course.hide_course && !course.closed && course.holes === course.hole_count).length) || []

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 30, width: '100%' }}>
                <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                    Total progress
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: 30 }}>
                <div style={{ height: 120, width: 120 }}>
                    <CircularProgressbar
                        style={{pathColor: `red`, textColor: 'red',
                        trailColor: 'red',
                    }}
                        maxValue={1}
                        value={completionPercent / 100}
                        text={`${completionPercent}%`}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%' }}>
                    <div>
                        {totalCompletedCourses.length}/{totalCourses.length} courses ({Math.round(totalCompletedCourses.length * 1000 / totalCourses.length) / 10}%)
                    </div>
                    <div>
                        {completedHoleLines}/{totalHoleLineCount} holes ({Math.round(completedHoleLines * 1000 / totalHoleLineCount) / 10}%)
                    </div>
                    <div>
                        {completedGreens}/{totalHoleLineCount} greens ({Math.round(completedGreens * 1000 / totalHoleLineCount) / 10}%)
                    </div>
                    <div>
                        {completedStates.length}/{states?.length} states ({Math.round(completedStates.length * 1000 / states?.length) / 10}%)
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15, width: '100%', paddingBottom: 30 }}>
                <SearchBar />
            </div>
            {states ? states.map(state => {
                const courses = state.courses.filter(course => !course.hide_course && !course.closed)
                const completedCourses = courses.filter(course => course.holes === course.hole_count)

                const completedHoleLineCount = completedCourses.reduce((acc, course) => {
                    return acc + course.hole_count
                }, 0)
                const completedGreenCount = completedCourses.reduce((acc, course) => {
                    return acc + course.green_count
                }, 0)
                const totalHoleLineCount = courses.reduce((acc, course) => {
                    return acc + course.holes
                }, 0)
                const completionPercent = Math.round(completedHoleLineCount * 1000 / totalHoleLineCount) / 10

                return <ListCard key={state.state_province} onClick={() => handleClick(state.state_province)}>
                    <span style={{ padding: 5, fontSize: 20 }}>{state.state_province}</span>
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div style={{ height: 100, width: 100 }}>
                            <CircularProgressbar
                                maxValue={1}
                                value={completionPercent / 100}
                                text={`${completionPercent}%`}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%' }}>
                            <div>
                                {completedCourses.length}/{state.courses.length} courses ({Math.round(completedCourses.length * 1000 / state.courses.length) / 10}%)
                            </div>
                            <div>
                                {completedHoleLineCount}/{totalHoleLineCount} holes ({Math.round(completedHoleLineCount * 1000 / totalHoleLineCount) / 10}%)
                            </div>
                            <div>
                                {completedGreenCount}/{totalHoleLineCount} greens ({Math.round(completedGreenCount * 1000 / totalHoleLineCount) / 10}%)
                            </div>
                        </div>
                    </div>
                </ListCard>
            }) : <div style={{ height: '100px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <SpinnerCircular size={80} />
                <span>Loading States List...</span>
            </div>}
        </div>
    )
}

export default StatesList