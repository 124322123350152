import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { useContext } from "react"
import courseStyleFunc, { courseIdentifierFunc } from "../../courseStyleFunc"
import MapContext from "./MapContext"

export const getLayer = (layerId, map) => {
    const layer = map?.getLayers().getArray().find(l => l.get('id') === layerId)
    if (layer) {
        return layer
    }
    const newLayer = new VectorLayer({
        id: layerId,
        name: layerId,
        source: new VectorSource({
            features: []
        }),
        style: courseIdentifierFunc(map),
    })
    map?.addLayer(newLayer)
    return newLayer
}

export const useMap = () => {
    const {map} = useContext(MapContext)
    return map
}