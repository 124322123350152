import { useContext, useState } from "react"
import MapContext from "./MapContext"
import Draw from 'ol/interaction/Draw'

import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';

import olStyleStyle from 'ol/style/Style'
import olStyleFill from 'ol/style/Fill'
import olStyleStroke from 'ol/style/Stroke'
import olStyleCircle from 'ol/style/Circle'
import { BOUND, BUNKER, CART, FAIRWAY, GREEN, HOLE, TEEBOX, WATER } from "../../featureTypes"
import polygonSmooth from "@turf/polygon-smooth"
import { geojsonToOl, olToGeojson } from "../../utils"

const getGeomFromType = (type) => {
    switch (type) {
        case GREEN:
        case TEEBOX:
        case BUNKER:
        case FAIRWAY:
        case BOUND:
            return "Polygon"
        case CART:
        case HOLE:
            return "LineString"
        default:
            return "Polygon"
    }
}

export const useDrawTools = () => {
    const { map } = useContext(MapContext)
    const [currentDrawInteraction, setCurrentDrawInteraction] = useState(null)

    const removeDrawInteraction = () => {
        const drawInteraction = currentDrawInteraction
        if (drawInteraction) {
            map.removeInteraction(drawInteraction)
            setCurrentDrawInteraction(null)
        }
    }

    const keyboardListener = (e) => {
        if (e.key === "Escape") {
            removeDrawInteraction()
            document.removeEventListener('keydown', keyboardListener)
        }
    }

    const getDrawLayer = () => {
        const existingLayer = map.getLayers().getArray().find((layer) => layer.get('title') === "DRAW")

        if (existingLayer) {
            return existingLayer
        }

        const newLayer = new VectorLayer({
            source: new VectorSource(),
            title: "DRAW",
            id: "DRAW",
            style: feature => {
                if (feature.get('type') === TEEBOX) {
                    return new olStyleStyle({
                        fill: new olStyleFill({
                            color: [0, 177, 106, 0.8],
                        }),
                        stroke: new olStyleStroke({
                            width: 2,
                            color: [0, 177, 106, 0.8]
                        }),
                        image: new olStyleCircle({
                            fill: new olStyleFill({
                              color: [255, 255, 255, 1]
                            }),
                            radius: 3,
                            stroke: new olStyleStroke({
                              color: [255, 255, 255, 1],
                              width: 3,
                            })
                          })
                    })
                } else if (feature.get('type') === FAIRWAY) {
                    return new olStyleStyle({
                        fill: new olStyleFill({
                            color: [0, 177, 106, 0.8],
                        }),
                        stroke: new olStyleStroke({
                            width: 2,
                            color: [0, 177, 106, 0.8]
                        }),
                        image: new olStyleCircle({
                            fill: new olStyleFill({
                              color: [255, 255, 255, 1]
                            }),
                            radius: 3,
                            stroke: new olStyleStroke({
                              color: [255, 255, 255, 1],
                              width: 3,
                            })
                          })
                    })
                } else if (feature.get('type') === GREEN) {
                    return new olStyleStyle({
                        fill: new olStyleFill({
                            color: [0, 230, 64, 0.8]
                        }),
                        stroke: new olStyleStroke({
                            width: 2,
                            color: [0, 230, 64, 0.8]
                        }),
                        image: new olStyleCircle({
                            fill: new olStyleFill({
                              color: [255, 255, 255, 1]
                            }),
                            radius: 3,
                            stroke: new olStyleStroke({
                              color: [255, 255, 255, 1],
                              width: 3,
                            })
                          })
                    })
                } else if (feature.get('type') === BUNKER) {
                    return new olStyleStyle({
                        fill: new olStyleFill({
                            color: [198, 179, 151, 0.8]
                        }),
                        image: new olStyleCircle({
                            fill: new olStyleFill({
                              color: [255, 255, 255, 1]
                            }),
                            radius: 3,
                            stroke: new olStyleStroke({
                              color: [255, 255, 255, 1],
                              width: 3,
                            })
                          })
                    })
                } else if (feature.get('type') === WATER) {
                    return new olStyleStyle({
                        fill: new olStyleFill({
                            color: [27, 163, 156, 0.8]
                        }),
                        image: new olStyleCircle({
                            fill: new olStyleFill({
                              color: [255, 255, 255, 1]
                            }),
                            radius: 3,
                            stroke: new olStyleStroke({
                              color: [255, 255, 255, 1],
                              width: 3,
                            })
                          })
                    })
                } else if (feature.get('type') === CART) {
                    return new olStyleStyle({
                        stroke: new olStyleStroke({
                            color: [0, 177, 106, 0.8],
                            width: 2
                        }),
                        image: new olStyleCircle({
                            fill: new olStyleFill({
                              color: [255, 255, 255, 1]
                            }),
                            radius: 3,
                            stroke: new olStyleStroke({
                              color: [255, 255, 255, 1],
                              width: 3,
                            })
                          })
                    })
                } else if (feature.get('type') === HOLE) {
                    return new olStyleStyle({
                        stroke: new olStyleStroke({
                            color: [0, 177, 106, 0.8],
                            width: 2,
                            lineDash: [3, 8]
                        }),
                        image: new olStyleCircle({
                            fill: new olStyleFill({
                              color: [255, 255, 255, 1]
                            }),
                            radius: 3,
                            stroke: new olStyleStroke({
                              color: [255, 255, 255, 1],
                              width: 3,
                            })
                          })
                    })
                }
            }
        })
        map.addLayer(newLayer)
        return newLayer
    }

    

    const startDraw = (type, onDrawEnd) => {
        map.getInteractions().getArray().forEach(interaction => {
            if (interaction instanceof DoubleClickZoom) {
                map.removeInteraction(interaction)
            }
        })

        const drawLayer = getDrawLayer()
        const drawInteraction = new Draw({
            source: drawLayer.getSource(),
            type: getGeomFromType(type),
        })
        drawInteraction.on('drawend', async (e) => {
            document.removeEventListener('keydown', keyboardListener)
            removeDrawInteraction()
            map.removeInteraction(drawInteraction)
            setCurrentDrawInteraction(null)

            map.removeLayer(getDrawLayer())

            setTimeout(() => map.addInteraction(new DoubleClickZoom()), 500)
            await onDrawEnd(e.feature)
        })

        map.addInteraction(drawInteraction)
        setCurrentDrawInteraction(drawInteraction)

        document.addEventListener('keydown', keyboardListener)
    }

    const stop = () => {
        removeDrawInteraction()
        map.removeInteraction(currentDrawInteraction)
        setCurrentDrawInteraction(null)
        map.addInteraction(new DoubleClickZoom())
    }

    return {
        startDraw,
        stop,
        currentDrawInteraction
    }
}

export const smoothPolygon = (olFeature) => {
    return geojsonToOl(polygonSmooth(olToGeojson(olFeature),  {iterations: 3}).features[0])
}
