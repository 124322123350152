import React, { useEffect, useState } from 'react'
import { getUserId, useVerifyTurkAuthOrRedirect } from '../../firebase'
import { geojsonToOl, olToGeojson, wktToFeature } from '../../utils'
import { getLayer, useMap } from '../map/utils'
import DrawFooter from './components/DrawFooter'
import InstructionModal from './components/InstructionModal'
import Map from '../map/Map'
import DrawHeader from './components/DrawHeader'
import CountHeader from './components/CountHeader'
import { smoothPolygon } from '../map/drawTools'
import { FeatureTypes } from '../../featureTypes'
import courseStyleFunc from '../../courseStyleFunc'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { VectorTile } from 'ol/layer'
import { VectorTile as VectorTileSource } from 'ol/source'
import { MVT } from 'ol/format'
import Badges from './components/Badges'


const EndlessDraw = ({ type = FeatureTypes.GREEN }) => {
    useVerifyTurkAuthOrRedirect()
    const { state, count } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const map = useMap()
    const [facility, setFacility] = useState(null)
    const [drawCount, setDrawCount] = useState(0)
    const [greenIds, setGreenIds] = useState([])
    const [showBadges, setShowBadges] = useState(false)
    const navigate = useNavigate()
    
    if (localStorage.getItem('tutorialComplete') !== 'true') {
        navigate('/turk/tutorial')
    }

    useEffect(() => {
        (async () => {
            if (searchParams.get('facilityId')) {
                const facilityId = searchParams.get('facilityId')
                    const response = await fetch(`https://atlas-api.services.divvit.co/courses/facility/${facilityId}`)
                    const facility = await response.json()
                    setFacility(facility)
            } else {
                fetchFacility()
            }
        })()
    }, [])

    const handleUndoClick = async () => {
        const greenLayer = getLayer(FeatureTypes.GREEN, map)

        if (greenIds.length > 0) {
            const greenId = greenIds[greenIds.length - 1]
            const greenFeature = greenLayer.getSource().getFeatures().find(g => g.get('id') == greenId)

            await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/${greenId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            greenLayer.getSource().removeFeature(greenFeature)
            setGreenIds(greenIds.filter(id => id != greenId))

            setDrawCount(drawCount - 1)
        }
    }

    useEffect(() => {
        if (map) {
            const vectorTileLayer = new VectorTile({
                source: new VectorTileSource({
                    format: new MVT(),
                    url: 'https://pg-tiles.services.divvit.co/public.polygon_features/{z}/{x}/{y}.pbf',
                }),
                style: courseStyleFunc(map)
            })
    
            map.addLayer(vectorTileLayer)
        }
    }, [map])

    useEffect(() => {
        if (facility && map) {
            const center = wktToFeature(facility.location, facility).getGeometry().getCoordinates()
            if (center) {
                map.getView().setCenter(center)
                map.getView().setZoom(16)
            }
        }
    }, [map, facility])

    const fetchFacility = async () => {
        const response = await fetch(`https://atlas-api.services.divvit.co/courses/facilities/random?${[{count}, {state}].filter(key => key[Object.keys(key)[0]]).map(k => `${Object.keys(k)[0]}=${k[Object.keys(k)[0]]}`).join('&')}`)
        const data = await response.json()
        setFacility(data)
        if (data.id) setSearchParams({facilityId: data.id})
    }

    const onDrawEnd = async (feature) => {
        const smoothed = smoothPolygon(feature)

        const response = await fetch(`https://atlas-api.services.divvit.co/geometries/polygons/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type: 'Feature',
                geometry: olToGeojson(smoothed).geometry,
                properties: {
                    type,
                    created_by: getUserId()
                }
            })
        }).then(res => res.json())

        setGreenIds([...greenIds, response.properties.id])
        setDrawCount(drawCount + 1)
        getLayer(FeatureTypes.GREEN, map).getSource().addFeature(geojsonToOl(response))
    }

    window.map = map

    return (
        <div>
            <DrawHeader onNewFacilityClick={() => fetchFacility()}/>
            {showBadges && <Badges onClose={() => setShowBadges(false)} />}
            <DrawFooter disableUndo={greenIds.length == 0} undoLastGreen={handleUndoClick} onDrawEnd={onDrawEnd} />
            <CountHeader count={drawCount} onClick={() => setShowBadges(true)} />
            <Map style={{width: '100vw', height: '100vh'}} />
        </div>
    )
}

export default EndlessDraw