import olStyleStyle from "ol/style/Style"
import olStyleFill from "ol/style/Fill"
import olStyleStroke from "ol/style/Stroke"

// import Style from 'ol/style/Style'
// import Stroke from 'ol/style/Stroke'
// import Fill from 'ol/style/Fill'
import Circle from 'ol/style/Circle'
import Text from 'ol/style/Text'
import Point from 'ol/geom/Point'

import {
    BUNKER,
    WATER,
    FAIRWAY,
    GREEN,
    TEEBOX,
    CART,
    HOLE,
} from "./featureTypes"

const fairwayCanvas = document.createElement("canvas")
const fairwayContext = fairwayCanvas.getContext("2d")
const fairwayPattern = (function () {
    fairwayCanvas.width = 20
    fairwayCanvas.height = 20
    // white background
    fairwayContext.fillStyle = "rgba(83, 122, 58, 0.33)"
    fairwayContext.fillRect(0, 0, 20, 10)
    fairwayContext.fill()
    // outer circle
    fairwayContext.fillStyle = "rgba(99, 134, 61, 0.33)"
    fairwayContext.fillRect(0, 10, 20, 10)
    fairwayContext.fill()

    return fairwayContext.createPattern(fairwayCanvas, "repeat")
})()

const courseStyleFunc = (map) => (feature) => {
    if (feature.get("type") === FAIRWAY) {
        return new olStyleStyle({
            fill: new olStyleFill({
                color: fairwayPattern,
            }),
        })
    } else if (feature.get("type") === BUNKER) {
        return new olStyleStyle({
            fill: new olStyleFill({
                color: [214, 207, 158, 0.5],
            }),
            stroke: new olStyleStroke({
              color: "#fff",
              width: 0.3,
          }),
        })
    } else if (feature.get("type") === CART) {
        return new olStyleStyle({
            stroke: new olStyleStroke({
                color: "#6e6e6e",
                width: 0.5,
            }),
        })
    } else if (feature.get("type") === HOLE) {
        return new olStyleStyle({
          stroke: new olStyleStroke({
            color: 'white', //feature.get("course_id") ? 'white' : "orange",
            width: 3,
            lineDash: [3, 8]
          })
        })
        return labeledHoleStyle(feature)
        
    } else if (feature.get("type") === WATER) {
        return new olStyleStyle({
            fill: new olStyleFill({
                color: [56, 117, 170, 0.5],
            }),
            // stroke: new olStyleStroke({
            //   color: [255,0,0],
            //   width: 2
            // }),
        })
    } else if (feature.get("type") === GREEN || feature.get("type") === TEEBOX) {
        if (!feature.get("course_id")) {
            return new olStyleStyle({
                fill: new olStyleFill({
                    color: [83, 122, 58, 0.5],
                }),
                stroke: new olStyleStroke({
                    color: [255, 255, 255, 1],
                    width: 2,
                }),
            })
        }
        return new olStyleStyle({
            fill: new olStyleFill({
                color: [132, 171, 83, 0.55],
            }),
        })
    } else {
        return new olStyleStyle({
            fill: new olStyleFill({
                color: [0, 165, 7, 0],
            }),
        })
    }
}

export default courseStyleFunc

export const labeledHoleStyle = (feature) => {
    const coord = feature.getGeometry().getCoordinateAt(0.5)
  
    return [
      new olStyleStyle({
        geometry: new Point(
          coord
        ),
        image: new Circle({
          radius: 15,
          fill: new olStyleFill({
            color: '#000'
          }),
          stroke: new olStyleStroke({
            color: 'gray',
            width: 3
          })
        })
      }),
      new olStyleStyle({
        stroke: new olStyleStroke({
          color: 'white', //feature.get("course_id") ? 'white' : "orange",
          width: 3,
          lineDash: [3, 8]
        }),
        text: new Text({
          font: '15px Arial',
          // text: `${feature.get('hole')}`, //${feature.get('name') !== 'undefined' ? ` - ${feature.get('name')}` : ''}
          textAlign: 'center',
          offsetX: 0,
          offsetY: 0,
          fill: new olStyleFill({
            color: '#ffffff'
          }),
        }),
      }),
    ]
}

  export const courseIdentifierFunc = (courseId) => (feature) => {
    if (feature.get("type") === HOLE && feature.get("course_id") === courseId) {
      return labeledHoleStyle(feature)
    } else if (feature.get("type") === HOLE && feature.get("course_id") !== courseId) {
      return new olStyleStyle({
        stroke: new olStyleStroke({
          color: "white",
          width: 3,
          lineDash: [3, 8]
        }),
      })
    } else {
        return courseStyleFunc()(feature)
    }
}

export const labeledFacilityStyleFunc = (feature) => {
  const color = feature.get('closed') ? 'red' : (feature.get('courses') || []).some(course => course.hide_course) ? '#FF7900' : (feature.get('courses') ?? []).every(course => course.holes === course.hole_count) ? [0, 7, 165, 1] : [200, 200, 200, 1]

  return new olStyleStyle({
    image: new Circle({
      radius: 4,
      fill: new olStyleFill({
        color: color,
      }),
      stroke: new olStyleStroke({
        color: color,
        width: 3,
      }),
    }),
    fill: new olStyleFill({
      color: color,
    }),
    text: new Text({
      font: '15px Arial',
      text: `${feature.get('name')}`,
      textAlign: 'left',
      offsetX: 10,
      offsetY: 0,
      fill: new olStyleFill({
        color: '#ffffff'
      }),
    }),
  })
}

export const facilityStyleFunc = (feature) => {
  const color = feature.get('closed') ? 'red' : (feature.get('courses') || []).some(course => course.hide_course) ? '#FF7900' : (feature.get('courses') ?? []).every(course => course.holes === course.hole_count) ? [0, 7, 165, 1] : [200, 200, 200, 1]

  return new olStyleStyle({
    image: new Circle({
      radius: 4,
      fill: new olStyleFill({
        color: color,
      }),
      stroke: new olStyleStroke({
        color: color,
        width: 3,
      }),
    }),
    fill: new olStyleFill({
      color: color,
    }),
  })
}

export const selectedLineStringStyle = new olStyleStyle({
  stroke: new olStyleStroke({
    color: '#87CEEB',
    width: 3,
  }),
})

export const selectedPolygonStyle = new olStyleStyle({
  fill: new olStyleFill({
    color: "#87CEEB",
  }),
  stroke: new olStyleStroke({
    color: '#87CEEB',
    width: 3,
  }),
})

export const courseEditLineStyle = (courseId) => (feature) => {
  const coord = feature.getGeometry().getCoordinateAt(0.5)
  
  return [
    ...((feature.get('associations') || []).find(assoc => assoc.course_id === courseId) ? [new olStyleStyle({
      geometry: new Point(
        coord
      ),
      image: new Circle({
        radius: 15,
        fill: new olStyleFill({
          color: '#000'
        }),
        stroke: new olStyleStroke({
          color: 'gray',
          width: 3
        })
      })
    }),] : []),
    new olStyleStyle({
      stroke: new olStyleStroke({
        color: 'white', //feature.get("course_id") ? 'white' : "orange",
        width: 3,
        lineDash: [3, 8]
      }),
      text: new Text({
        font: '15px Arial',
        text: `${(feature.get('associations') || []).find(assoc => assoc.course_id === courseId)?.hole || ''}`,
        textAlign: 'center',
        offsetX: 0,
        offsetY: 0,
        fill: new olStyleFill({
          color: '#ffffff'
        }),
      }),
    }),
  ]
}

  
