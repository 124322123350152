import React from 'react'
import Header from '../Header'
import Sidebar from './Sidebar'
import Map from '../map/Map'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'

const SplitPageLayout = styled.div`
  display: flex;
  flex-direction: row;
`

const Router = () => {
    return (<>
        <Header></Header>
        <SplitPageLayout>
            <Sidebar/>
            <Map style={{ height: "calc(100% - 45px)", width: "calc(100% - 400px)", position: 'absolute', right: '0' }}></Map>
        </SplitPageLayout>
    </>)
}

export default Router