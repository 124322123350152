import { Checkbox, CircularProgress, Divider } from "@mui/material"

const ShapesLoading = ({ greensLoading, holeLinesLoading }) => {

    const loading = greensLoading || holeLinesLoading

  if (loading) {
    return (
      <div style={{ transition: '1s', position: 'absolute', top: 0, right: 0, margin: 20, backgroundColor: 'white', borderRadius: 10, zIndex: 200 }}>
        <div style={{ display: 'flex', alignItems: 'center', padding: 8 }}><div style={{ marginRight: 12 }}>{greensLoading ? <CircularProgress /> : <Checkbox checked={true} />}</div><span>Greens</span></div>
        <Divider />
        <div style={{ display: 'flex', alignItems: 'center', padding: 8 }}><div style={{ marginRight: 12 }}>{holeLinesLoading ? <div><CircularProgress /></div> : <Checkbox checked={true} />}</div><span>Hole Lines</span></div>
      </div>
    )
  }

  return null
}

export default ShapesLoading