import React from 'react'
import styled from 'styled-components'

const Badge = styled.div`
    display: flex;
    font-size: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #009E6355;
    border-radius: 10px;
    padding: 10px;
    margin: 3px;
    color: black;
    cursor: 'pointer';
`

const CountBadge = ({ count, label, onClick }) => {
  return (
    <Badge
      onClick={onClick}
    >
      <div style={{ fontSize: '1rem', fontWeight: '600' }}>{label}</div>
      <div style={{ fontSize: '0.8rem', fontWeight: '600', color: '009E63ee' }}>x{count}</div>
    </Badge>
  )
}

export default CountBadge