import React, { useState, useEffect, useContext } from 'react'
import MapContext from '../../components/map/MapContext'
import { FeatureTypes, getGeometryFromType } from '../../featureTypes'
import styled from "styled-components"
import { useParams } from 'react-router-dom'
import { smoothPolygon, useDrawTools } from '../../components/map/drawTools'
import { geojsonToOl, olToGeojson } from '../../utils'
import intersects from '@turf/boolean-intersects'
import courseStyleFunc, { courseEditLineStyle, selectedLineStringStyle } from '../../courseStyleFunc'
import { getUserId } from '../../firebase'
import { getLayer } from '../../components/map/utils'

const ListCard = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 17px;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: ${p => !p.isSelected ? '0 2px 14px rgba(0,0,0,0.1),0 -1px 0px rgba(0,0,0,0.02)' : '0 2px 14px rgba(0,0,0,0.1),0 -1px 0px rgba(0,0,0,0.02), 0 0 0 3px #009E63'};
`

const HoleButton = styled.button`
    background: ${p => p.selected ? 'lightgreen' : p.disabled ? "lightgray" : "#ffffff"};
    border: ${props => props.selected ? "1px solid green" : "1px solid #000000"};
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
`

const ActionButton = styled.button`
    background: ${p => p.red ? 'red' : 'white'};
    color: ${p => p.red ? 'white' : 'black'};
    border: 1px solid #000000;
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 8px;
    margin: 5px;
`

const Select = styled.select`
    background: white;
    color: black;
    border: 1px solid #000000;
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 8px;
    margin: 5px;
`

const polygonTypes = [
    FeatureTypes.GREEN,
    FeatureTypes.WATER,
    FeatureTypes.BUNKER,
    FeatureTypes.TEEBOX,
    FeatureTypes.FAIRWAY
]

const selectableLayers = [FeatureTypes.HOLE, FeatureTypes.GREEN]

const FeatureTools = ({
    holeCount = null,
    associateIntersecting,
    reloadPage = () => { },
}) => {
    const [selectedFeatures, setSelectedFeatures] = useState([])
    const { courseId } = useParams()
    const { map } = useContext(MapContext)
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0)
    const reset = useState(false)[1]

    const updateScreen = () => reset(r => !r)

    const mapClickHandler = (e) => {
        setSelectedFeatureIndex(0)
        const allFeatures = map?.getLayers().getArray().filter(l => selectableLayers.includes(l.get('id'))).reduce((acc, layer) => [...acc, ...(layer?.getSource()?.getFeatures?.() || [])], []) || []
        allFeatures.forEach(f => 
            f.get('type') == FeatureTypes.HOLE ? f.setStyle(courseEditLineStyle(courseId)) : f.setStyle(courseStyleFunc(map)))

        const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 4, layerFilter: l => selectableLayers.includes(l.get('id')) })
        if (associateIntersecting) {
            features.filter(f => f.get('type') == FeatureTypes.HOLE).forEach(f => {
                const intersecting = map.getLayers().getArray().reduce((acc, layer) => {
                    if (layer.getSource) {
                        const layerFeatures = layer?.getSource?.()?.getFeatures?.() ?? []
                        const intersect = layerFeatures.filter(lf => {
                            if (lf.get('type') !== FeatureTypes.HOLE && !features.map(fr => fr.get('id')).includes(lf.get('id'))) {
                                return intersects(olToGeojson(f), olToGeojson(lf))
                            }
                            return false
                        })
                        return [...acc, ...intersect]
                    }
                }, [])
                features.push(...intersecting)
            })
        }
        if (features?.length === 0) {
            setSelectedFeatures([])
        } else if (features?.length > 0) {
            setSelectedFeatures(features)
            features.forEach(f => {
                f.set('prevStyle', f.getStyle())
                if (f.get('type') === FeatureTypes.HOLE) {
                    f.setStyle(selectedLineStringStyle)
                } else {
                    f.setStyle(selectedLineStringStyle)
                }
            })
        }
    }

    const mapDoubleCLickHandler = (e) => {

        console.log("double click", e)
        const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 4 })
        console.log("features", features)
        if (features?.length === 1 && getGeometryFromType(features[0].get('type')) == 'Polygon') {
            e.stopPropagation()
            console.log("this?")
            const feature = features[0]
            const id = feature.get('id')
            const url = `https://atlas-api.services.divvit.co/geometries/polygons/${id}`
            const smoothed = smoothPolygon(feature)

            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    geometry: olToGeojson(smoothed).geometry
                })
            }).then(res => res.json()).then(data => {
                feature.setGeometry(smoothed.getGeometry())
                updateScreen()
            })
        }
    }

    const keyboardHandler = async (e) => {
        if (['Backspace', 'Delete'].includes(e.key)) {
            deleteFeature(selectedFeatures[selectedFeatureIndex])
        } else if (e.key === 'ArrowUp' && !!selectedFeatureIndex[selectedFeatureIndex + 1]) {
            e.stopPropagation()
            setSelectedFeatureIndex(selectedFeatureIndex + 1)
        } else if (e.key === 'ArrowDown' && !!selectedFeatureIndex[selectedFeatureIndex - 1]) {
            e.stopPropagation()
            setSelectedFeatureIndex(selectedFeatureIndex - 1)
        } else if (e.key === 'Escape') {
            setSelectedFeatures([])
        } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')'].includes(e.key) && selectedFeatures.length > 0) {
            const mapping = {
                '1': 1,
                '2': 2,
                '3': 3,
                '4': 4,
                '5': 5,
                '6': 6,
                '7': 7,
                '8': 8,
                '9': 9,
                '0': 10,
                ')': 10,
                '!': 11,
                '@': 12,
                '#': 13,
                '$': 14,
                '%': 15,
                '^': 16,
                '&': 17,
                '*': 18
            }
            console.log("selectedFeatures", selectedFeatures.map(olToGeojson))
            for (let f of selectedFeatures) {
                await handleHoleNumber(f, mapping[e.key], { assocIntersecting: false })
            }
            updateScreen()
            reloadPage()
        } else if (e.key === 'r') {
            updateScreen()
            reloadPage()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyboardHandler)
        return () => document.removeEventListener('keydown', keyboardHandler)
    }, [selectedFeatures])

    useEffect(() => {
        if (map) {
            map.on('click', mapClickHandler)
            map.on('dblclick', mapDoubleCLickHandler)
        }

        return () => {
            if (map) {
                map.un('click', mapClickHandler)
                map.un('dblclick', mapDoubleCLickHandler)
            }
        }
    }, [map, associateIntersecting])

    const deleteFeature = (feature) => {
        const id = feature.get('id')
        const url = `https://atlas-api.services.divvit.co/geometries/${feature.get('type') === FeatureTypes.HOLE ? 'lines' : 'polygons'}/${id}`
        if (feature.get('associations')?.filter(e => e).length > 0 && confirm('This feature is associated to a course. Are you sure you want to delete it?')) {
            setSelectedFeatures(features => features.filter(f => f !== feature))
            fetch(url, {
              method: 'DELETE',
            }).then(res => res.json()).then(data => {
              const layerId = feature.get('type') === FeatureTypes.HOLE ? FeatureTypes.HOLE : FeatureTypes.GREEN
              map.getLayers().getArray().find(layer => layer.get('id') === layerId).getSource().removeFeature(feature)
              updateScreen()
              reloadPage()
            })
        } else if (!feature.get('associations')?.filter(e => e).length) {
            setSelectedFeatures(features => features.filter(f => f !== feature))
            fetch(url, {
                method: 'DELETE',
            }).then(res => res.json()).then(data => {
                const layerId = feature.get('type') === FeatureTypes.HOLE ? FeatureTypes.HOLE : FeatureTypes.GREEN
                map.getLayers().getArray().find(layer => layer.get('id') === layerId).getSource().removeFeature(feature)
                updateScreen()
                reloadPage()
            })
        }
    }

    const drawTools = useDrawTools()
    const holes = Array.from({ length: holeCount }, (v, i) => i + 1)

    const isHoleTaken = (feature, hole) => {
        const layerId = feature.get('type') === FeatureTypes.HOLE ? FeatureTypes.HOLE : FeatureTypes.GREEN

        const features = map.getLayers().getArray().find(layer => layer.get('id') == layerId).getSource().getFeatures() || []
        const usedHoles = features.filter(f => f.get('type') == FeatureTypes.GREEN || f.get('type') == FeatureTypes.HOLE).filter(f => f.get('associations')?.filter(e => e).some(a => a?.course_id == courseId)).reduce((acc, f) => [...acc, ...f.get('associations')?.filter(a => a?.course_id == courseId)], []).map(a => a.hole)
        return usedHoles.includes(hole)
    }

    const updateAssociation = async (feature, hole, {assocIntersecting = associateIntersecting}) => {
        // update hole number - only applies to hole lines, which are 1:1 with holes

        const association = feature.get('associations')?.filter(e => e).find(a => a?.course_id == courseId)
        const url = `https://atlas-api.services.divvit.co/associations/${getGeometryFromType(feature.get('type')) == 'Polygon' ? 'polygon' : 'line'}/${association.id}`
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                hole
            })
        }).then(r => r.json())
        feature.set('associations', feature.get('associations')?.map(a => a.id == association.id ? response : a))
        reloadPage()
        updateScreen()

        if (assocIntersecting && feature.get('type') === FeatureTypes.HOLE) {
            const intersecting = map.getLayers().getArray().reduce((acc, layer) => {
                if (layer.getSource) {
                    const features = layer?.getSource?.()?.getFeatures?.() ?? []
                    const intersect = features.filter(f => {
                        if (f.get('type') !== FeatureTypes.HOLE) {
                            return intersects(olToGeojson(f), olToGeojson(feature))
                        }
                        return false
                    })
                    return [...acc, ...intersect]
                }
            }, [])
            for (let f of intersecting) {
                if (f.get('type') !== FeatureTypes.HOLE && f.get('associations')?.filter(e => e).some(a => a?.course_id == courseId && a?.hole == hole)) {
                    await updateAssociation(f, hole, {assocIntersecting})
                    reloadPage()
                    updateScreen()
                }
            }
        }
    }

    const deleteAssociation = async (feature, hole, {assocIntersecting = associateIntersecting}) => {
        const association = feature.get('associations')?.filter(e => e).find(a => a?.course_id == courseId && a?.hole == hole)
        const url = `https://atlas-api.services.divvit.co/associations/${getGeometryFromType(feature.get('type')) == 'Polygon' ? 'polygon' : 'line'}/${association.id}`
        await fetch(url, {
            method: 'DELETE'
        })
        feature.set('associations', feature.get('associations')?.filter(a => a.id != association.id))
        reloadPage()
        updateScreen()

        if (assocIntersecting && feature.get('type') === FeatureTypes.HOLE) {
            const intersecting = map.getLayers().getArray().reduce((acc, layer) => {
                if (layer.getSource) {
                    const features = layer?.getSource?.()?.getFeatures?.() ?? []
                    const intersect = features.filter(f => {
                        if (f.get('type') !== FeatureTypes.HOLE) {
                            return intersects(olToGeojson(f), olToGeojson(feature))
                        }
                        return false
                    })
                    return [...acc, ...intersect]
                }
            }, [])
            for (let f of intersecting) {
                if (f.get('type') !== FeatureTypes.HOLE && f.get('associations')?.filter(e => e).some(a => a?.course_id == courseId && a?.hole == hole)) {
                    await deleteAssociation(f, hole, {assocIntersecting})
                    updateScreen()
                    reloadPage()
                }
            }
        }
    }

    const createAssociation = async (feature, hole, {assocIntersecting = associateIntersecting} = {}) => {
        // create association

        const url = `https://atlas-api.services.divvit.co/associations/${getGeometryFromType(feature.get('type')) == 'Polygon' ? 'polygon' : 'line'}`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                hole,
                course_id: courseId,
                feature_id: feature.get('id'),
                created_by: getUserId(),
            })
        }).then(r => r.json())
        feature.set('associations', [...(feature.get('associations') || []), response])
        reloadPage()
        updateScreen()

        if (assocIntersecting && feature.get('type') === FeatureTypes.HOLE) {
            const intersecting = map.getLayers().getArray().reduce((acc, layer) => {
                if (layer.getSource) {
                    const features = layer?.getSource?.()?.getFeatures?.() ?? []
                    const intersect = features.filter(f => {
                        if (f.get('type') !== FeatureTypes.HOLE) {
                            return intersects(olToGeojson(f), olToGeojson(feature))
                        }
                        return false
                    })
                    return [...acc, ...intersect]
                }
            }, [])
            for (let f of intersecting) {
                if (f.get('type') !== FeatureTypes.HOLE) {
                    await createAssociation(f, hole, {assocIntersecting})
                    reloadPage()
                    updateScreen()
                }
            }
        }
    }

    const handleHoleNumber = async (feature, hole, {assocIntersecting = associateIntersecting} = {}) => {
        if (isHoleTaken(feature, hole) && !isCurrentlySelectedHole(feature, hole)) {
            alert('Hole number is already taken')
            return
        }
        if (!holes.includes(hole)) {
            alert('Hole number is not in course')
            return
        }
        if (isCurrentlySelectedHole(feature, hole)) {
            await deleteAssociation(feature, hole, {assocIntersecting})
        } else if (feature.get('associations')?.filter(e => e).some(a => a?.course_id == courseId) && feature.get('type') === FeatureTypes.HOLE) {
            await updateAssociation(feature, hole, {assocIntersecting})
        } else {
            await createAssociation(feature, hole, {assocIntersecting})
        }
        updateScreen()
        reloadPage()
    }

    const isCurrentlySelectedHole = (feature, hole) => {
        return !!feature.get('associations')?.filter(e => e).find(a => a?.course_id == courseId && a?.hole == hole)
    }

    const handleRedraw = (oldFeature) => {
        const layerId = oldFeature.get('type') === FeatureTypes.HOLE ? FeatureTypes.HOLE : FeatureTypes.GREEN
        map.getLayers().getArray().find(layer => layer.get('id') === layerId).getSource().removeFeature(oldFeature)

        drawTools.startDraw(oldFeature.get('type'), async (feature) => {
            const smoothed = getGeometryFromType(oldFeature.get('type')) == 'Polygon' ? smoothPolygon(feature) : feature

            const url = `https://atlas-api.services.divvit.co/geometries/${getGeometryFromType(oldFeature.get('type')) == 'Polygon' ? 'polygons' : 'lines'}/${oldFeature.get('id')}`
            const editedGeojson = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    geometry: olToGeojson(smoothed).geometry
                })
            }).then(r => r.json())

            map.getLayers().getArray().find(layer => layer.get('id') === layerId).getSource().addFeature(geojsonToOl(editedGeojson))
            setSelectedFeatures(features => [...features.filter(f => f !== oldFeature), geojsonToOl(editedGeojson)])
            updateScreen()
        })
    }

    const handleChangeType = async (feature, type) => {
        const layerId = feature.get('type') === FeatureTypes.HOLE ? FeatureTypes.HOLE : FeatureTypes.GREEN
        getLayer(layerId, map).getSource().removeFeature(feature)
        setSelectedFeatures(features => [...features.filter(f => f.get('id') !== feature.get('id'))])
        feature.set('type', type)

        const url = `https://atlas-api.services.divvit.co/geometries/${getGeometryFromType(feature.get('type')) == 'Polygon' ? 'polygons' : 'lines'}/${feature.get('id')}`
        const person = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                type
            })
        }).then(r => r.json())

        getLayer(layerId, map).getSource().addFeature(geojsonToOl(person))
        updateScreen()
    }

    return (
        <div>
            {selectedFeatures.filter(f => f.get('id') && f.get('type')).map((feature, i) => (
                <ListCard isSelected={i === selectedFeatureIndex}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {feature.get('type') != FeatureTypes.HOLE ? <Select value={feature.get('type')} onChange={(e) => handleChangeType(feature, e.target.value)}>
                            {polygonTypes.map(type => (
                                <option selected={feature.get('type') === type}>{type}</option>
                            ))}
                        </Select> : <div>{feature.get('type')}</div>}
                        <div onClick={() => navigator.clipboard.writeText(feature.get('id'))}>{feature.get('id').substring(0, 8)}</div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <ActionButton red onClick={() => deleteFeature(feature)}>Delete</ActionButton>
                        <ActionButton onClick={() => handleRedraw(feature)}>Redraw</ActionButton>
                    </div>
                    {courseId && holeCount ? <div>
                        {holes.map(hole => (
                            <HoleButton disabled={isHoleTaken(feature, hole) && !isCurrentlySelectedHole(feature, hole) && [FeatureTypes.GREEN, FeatureTypes.HOLE].includes(feature.get('type'))} selected={isCurrentlySelectedHole(feature, hole)} onClick={() => handleHoleNumber(feature, hole)}>{hole}</HoleButton>
                        ))}
                    </div> : null}
                </ListCard>
            ))}
        </div>
    )

}

export default FeatureTools
