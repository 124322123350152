import React from "react";
import styled from "styled-components";

const BadgeDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    color: black;
    width: 100%;
    margin: 10px;
`

const LevelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #009E6355;
    border-radius: 10px;
    padding: 10px;
    margin: 3px;
    color: black;
    cursor: 'pointer';
    width: calc(100% - 20px);
    overflow: hidden;
    opacity: ${props => props.completed ? 1 : 0.65};
`

const BadgeTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
`

const ProgressBadge = ({ count, goal, label, description }) => {
    const completed = count >= goal
    return (
        <LevelContainer completed={completed}>
            <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column', justifyContent: 'left' }}>
                <BadgeTitle style={{ fontSize: '1.2rem' }}>
                    {label}
                </BadgeTitle>
                <div style={{ fontSize: '0.8rem' }}>
                    {description}
                </div>
            </div>
            {completed && <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
                <path fill="#009E63" d="M9,16.17L4.83,12L3.41,13.41L9,19L21,7L19.59,5.59L9,16.17Z" />
            </svg>}
            {!completed && <div style={{ fontSize: '0.8rem', color: '009E63ee' }}>{count.toLocaleString()}/{goal.toLocaleString()}</div>}
        </LevelContainer>
    )
}


const BadgeDetails = ({ badge }) => {
    console.log(badge)

    if (badge.badgeCount !== undefined) {
        return (
            <BadgeDetailContainer>
                <div style={{ fontSize: '1.4rem', fontWeight: '600' }}>{badge.name}</div>
                {badge.levels.map(level => {
                    return (
                        <LevelContainer completed={(badge?.completedLevels?.[level.name.toLowerCase()] ?? 0) > 0}>
                            <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column', justifyContent: 'left' }}>
                                <BadgeTitle style={{ fontSize: '1.2rem' }}>
                                    {level.name}
                                </BadgeTitle>
                                <div style={{ fontSize: '0.8rem' }}>
                                    {level.description}
                                </div>
                            </div>
                            <div style={{ fontSize: '0.8rem', color: '#111144' }}>
                                x{badge?.completedLevels?.[level.name.toLowerCase()] ?? 0}
                            </div>
                        </LevelContainer>
                    )
                })}
            </BadgeDetailContainer>
        )
    }

    return (
        <BadgeDetailContainer>
            <div style={{ fontSize: '1rem', fontWeight: '600' }}>{badge.name}</div>
            {badge.levels.map(level => {
                return (
                    <ProgressBadge
                        completed={level.completed}
                        count={badge.count}
                        goal={level.count}
                        label={level.name}
                        description={level.description}
                    />
                )
            })}
        </BadgeDetailContainer>
    )
}
    
export default BadgeDetails