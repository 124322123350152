// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    OAuthProvider,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBjBv6okGZAwTMGrukg42gTU5wth9V2QMY",
    authDomain: "atlas-7594a.firebaseapp.com",
    projectId: "atlas-7594a",
    storageBucket: "atlas-7594a.appspot.com",
    messagingSenderId: "36987449204",
    appId: "1:36987449204:web:3be88ee9ecbcc8a81f8621",
    measurementId: "G-NJD1KT8WHY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);


const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log(user, token);
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });

};

const signInWithApple = async () => {
    const provider = new OAuthProvider("apple.com");
    const auth = getAuth();
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = OAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            console.log(user, token
            );
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = OAuthProvider.credentialFromError(error);
            // ...
        });
};

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            name,
            authProvider: "local",
            email,
        });
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
};

const getUserId = () => {
    const user = auth.currentUser;
    return user ? user.uid : null;
};

const useVerifyAuthOrRedirect = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth);
    useEffect(() => {
        if (loading) return;
        if (error) {
            console.error(error);
            return;
        }
        if (!user) {
            localStorage.setItem('previousUrl', window.location.pathname)
            navigate("/login");
        }
    }, [user, loading, error, navigate]);
};

const useVerifyTurkAuthOrRedirect = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth);
    useEffect(() => {
        if (loading) return;
        if (error) {
            console.error(error);
            return;
        }
        if (!user) {
            localStorage.setItem('previousUrl', window.location.pathname)
            navigate("/turk/login");
        }
    }, [user, loading, error, navigate]);
};

window.logout = () => {
    logout()
    window.location.reload()
}

export {
    auth,
    analytics,
    db,
    getUserId,
    signInWithGoogle,
    signInWithApple,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    useVerifyAuthOrRedirect,
    useVerifyTurkAuthOrRedirect,
};
