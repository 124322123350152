export const BUNKER = "BUNKER"
export const CART = "CART"
export const COURSE = "COURSE"
export const FAIRWAY = "FAIRWAY"
export const GREEN = "GREEN"
export const HOLE = "HOLE"
export const TEEBOX = "TEEBOX"
export const TREE = "TREE"
export const WATER = "WATER"
export const WOODS = "WOODS"

export const FRONT_GREEN = "FRONT_GREEN"
export const BACK_GREEN = "BACK_GREEN"
export const CENTER_GREEN = "CENTER_GREEN"

export const BOUND = "BOUND"

export class FeatureTypes {
    static BUNKER = "BUNKER";
    static CART = "CART";
    static COURSE = "COURSE";
    static FAIRWAY = "FAIRWAY";
    static GREEN = "GREEN";
    static HOLE = "HOLE";
    static TEEBOX = "TEEBOX";
    static TREE = "TREE";
    static WATER = "WATER";
    static WOODS = "WOODS";
    static ROUGH = "ROUGH";

    static FRONT_GREEN = "FRONT_GREEN";
    static BACK_GREEN = "BACK_GREEN";
    static CENTER_GREEN = "CENTER_GREEN";

    static BOUND = "BOUND";

    static FACILITY = "FACILITY";
}

export const getGeometryFromType = (type) => {
    switch (type) {
        case GREEN:
        case TEEBOX:
        case BUNKER:
        case FAIRWAY:
        case BOUND:
        case WATER:
        case WOODS:
        case COURSE:
            return "Polygon"
        case CART:
        case HOLE:
            return "LineString"
        case FRONT_GREEN:
        case BACK_GREEN:
        case CENTER_GREEN:
        case TREE:
            return "Point"
        default:
            return "Polygon"
    }
}
