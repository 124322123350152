import React from 'react'
import styled from 'styled-components'

const Badge = styled.div`
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    background-color: #009E6355;
    border-radius: 10px;
    margin: 3px;
    color: black;
    cursor: 'pointer';
    overflow: hidden;
`

const ProgressBadge = ({ count, levels, label, onClick }) => {
    const activeLevel = levels.filter(level => !level.achieved).sort((a, b) => a.count - b.count)[0]

    return (
        <Badge
            onClick={onClick}
        >
            <div style={{ padding: 10, paddingBottom: 5 }}>{label}</div>
            <div style={{ display: 'flex', height: 5, width: '100%' }}>
                <div style={{ width: `${count* 100/activeLevel.count}%`, backgroundColor: 'green', height: 5, borderRadius: 2.5 }} />
                <div style={{ width: `${100 - count* 100/activeLevel.count}%`, height: 5 }} />
            </div>
        </Badge>
    )
}

export default ProgressBadge