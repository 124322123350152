import React, { useContext, useEffect, useState } from 'react'
import MapContext from '../../components/map/MapContext'
import { FeatureTypes, getGeometryFromType } from '../../featureTypes'
import { olToGeojson } from '../../utils'
import intersects from '@turf/boolean-intersects'
import { getUserId } from '../../firebase'

const InOrderMode = ({ course, associateIntersecting, rerenderPage, quitMode }) => {

    const { map } = useContext(MapContext)
    const [activeHole, setActiveHole] = useState()

    const clickListener = async (e) => {
        const features = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 4 })
        const feature = features?.[0]

        if (feature && feature?.get && feature.get('type') == FeatureTypes.HOLE && !feature.get('associations')?.filter(e => e).find(a => a.course_id == course.id && a.hole != null)) {
            if (feature.get('type') == FeatureTypes.HOLE) {
                await handleFeatureAssignment(feature)
                if (activeHole == course.holes) {
                    quitMode()
                } else {
                    setActiveHole(hole => hole + 1)
                }
                rerenderPage()
            }
        }
    }

    useEffect(() => {
        if (map) {
            // find which hole is active
            const holeLinesLayer = map.getLayers().getArray().find(l => l.get('id') == FeatureTypes.HOLE)
            const holeLines = holeLinesLayer?.getSource()?.getFeatures() || []
            const highestHole = holeLines.reduce((acc, cur) => {
                const number = cur.get('associations')?.find(a => a.course_id == course.id)?.hole || 0
                if (number > acc) {
                    acc = number
                }
                return acc
            }, 0)
            setActiveHole(highestHole + 1)

            // add click listener
            map.on('click', clickListener)
        }
        return () => {
            if (map) {
                map.un('click', clickListener)
            }
        }
    }, [map, activeHole])

    const handleFeatureAssignment = async (feature) => {
        const url = `https://atlas-api.services.divvit.co/associations/${getGeometryFromType(feature.get('type')) == 'Polygon' ? 'polygon' : 'line'}`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                hole: activeHole,
                course_id: course.id,
                feature_id: feature.get('id'),
                created_by: getUserId(),
            })
        }).then(r => r.json())
        feature.set('associations', [...(feature.get('associations') || []), response])
        rerenderPage()

        if (associateIntersecting && feature.get('type') == FeatureTypes.HOLE) {
            const intersecting = map.getLayers().getArray().reduce((acc, layer) => {
                if (layer.getSource) {
                    const features = layer?.getSource?.()?.getFeatures?.() ?? []
                    const intersect = features.filter(f => {
                        if (f.get('type') !== FeatureTypes.HOLE) {
                            return intersects(olToGeojson(f), olToGeojson(feature))
                        }
                        return false
                    })
                    return [...acc, ...intersect]
                }
            }, [])
            intersecting.forEach(f => {
                if (f.get('type') !== FeatureTypes.HOLE) {
                    handleFeatureAssignment(f)
                }
            })
        }
        
    }

    return (
        <div>
            <p>
                Click on hole lines in order starting with {activeHole}. Press `Stop` when done, or once you need to start drawing again. Don't worry, your progress saves as you go.
            </p>
            <div style={{ fontSize: 20 }}>
                Current Hole: {activeHole}
            </div>
        </div>
    )
}

export default InOrderMode