import React, { useEffect } from "react";
import styled from "styled-components";
import { GREEN } from "../../../featureTypes";
import { useDrawTools } from "../../map/drawTools";
import "./ripple.css"

const DrawButton = styled.button`
    border: none;
    border-radius: 2px;
    padding: 12px 18px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    background-color: #2196f3;
    box-shadow: 0 0 4px #999;
    outline: none;
`

const DrawContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: flex;
`

const DrawFooter = ({ onDrawEnd, undoLastGreen, disableUndo }) => {
    const drawTools = useDrawTools()

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape" && drawTools.currentDrawInteraction) {
                drawTools.stop()
            }
            if (e.key === "Enter" && drawTools.currentDrawInteraction) {
                drawTools.currentDrawInteraction.finishDrawing()
            }
            if (e.key === "d" && !drawTools.currentDrawInteraction) {
                drawTools.startDraw(GREEN, onDrawEnd)
            }
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [drawTools])

    const handleUndoClick = () => {
        undoLastGreen()
    }

    return (
        <DrawContainer>
            {!drawTools.currentDrawInteraction ? <>
                <DrawButton style={{backgroundColor: 'red'}} disabled={disableUndo} className="ripple" onClick={handleUndoClick}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'center' }}>
                        <div style={{ height: '1.5rem', width: '1.5rem', marginRight: '.5rem'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24"><path d="M19,13H5.83L11.42,7.41L10,6L3,13L10,20L11.41,18.59L5.83,13H19V11H5.83L11.42,5.41L10,4L3,11L10,18L11.41,16.59L5.83,11H19V13Z" color="white"/></svg>
                        </div>
                        Undo
                    </div>
                </DrawButton>
                <DrawButton style={{ marginLeft: 10 }}  className="ripple" onClick={() => drawTools.startDraw(GREEN, onDrawEnd)}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'center' }}>
                        <div style={{ height: '1.5rem', width: '1.5rem', marginRight: '.5rem'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" color="white"/></svg>
                        </div>
                        Draw
                    </div>
                </DrawButton>
            </>
                : <>
                    <DrawButton style={{backgroundColor: 'red'}} className="ripple" onClick={() => drawTools.stop()}>Stop [esc]</DrawButton>
                    <DrawButton style={{ marginLeft: 10 }} className="ripple" onClick={() => drawTools.currentDrawInteraction.finishDrawing()}>Finish [enter]</DrawButton>
                </>}
        </DrawContainer>
    );
}

export default DrawFooter;
