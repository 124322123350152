import WKT from 'ol/format/WKT'
import GeoJSON from 'ol/format/GeoJSON'

const format = new GeoJSON()
const projectionOpts = {
  decimals: 8,
  dataProjection: 'EPSG:4326',
  featureProjection: 'EPSG:3857'
}

export const olToGeojson = (feature) => {
  feature.unset('_ol_kit_parent')
  feature.unset('_ol_kit_feature_style')
  feature.unset('_ol_kit_feature_visibility')
  return format.writeFeatureObject(feature, projectionOpts)
}

export const geojsonToOl = (feature) => {
  if (feature.type === "FeatureCollection") {
    return format.readFeatures(feature, projectionOpts)
  } else {
    return format.readFeature(feature, projectionOpts)
  }
}

export const wktToFeature = (geom, props) => {
  const format = new WKT()
  const feature = format.readFeature(geom, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  })

  // if there are props, set those on the feature
  if (props) feature.setProperties(props)

  return feature
}

export const featureToWkt = (feature) => {
  const format = new WKT()
  return format.writeFeature(feature, {
    dataProjection: 'EPSG:4326',
    featureProjection: 'EPSG:3857',
  })
}