import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithApple,
  signInWithGoogle,
} from "../../firebase";
import styled from "styled-components"

const RegisterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #009D63;
    height: 100vh;
`
const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    width: 300px;
    height: 300px;
`
const RegisterTextBox = styled.input`
    border: 1px solid #009D63;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: 200px;
`
const RegisterButton = styled.button`
    border: 1px solid #009D63;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: 200px;
    background: #009D63;
    color: #ffffff;
    font-weight: 600;
`
const RegisterWithGoogleButton = styled.button`
    border: 1px solid #009D63;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: 200px;
    background: #ffffff;
    color: #009D63;
    font-weight: 600;
`


const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate()
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading]);
  console.log("user", user)
  return (
    <RegisterWrapper>
      <RegisterContainer>
        <RegisterTextBox
          type="text"
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <RegisterTextBox
          type="text"
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <RegisterTextBox
          type="password"
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <RegisterButton className="register__btn" onClick={register}>
          Register
        </RegisterButton>
        <RegisterWithGoogleButton
          onClick={() => signInWithGoogle()}
        >
          Register with Google
        </RegisterWithGoogleButton>
        <RegisterWithGoogleButton onClick={signInWithApple}>
          Register with Apple
        </RegisterWithGoogleButton>
        <div>
          Already have an account? <Link to="/login">Login</Link> now.
        </div>
      </RegisterContainer>
    </RegisterWrapper>
  );
}
export default Register;