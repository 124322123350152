import React, { useState, useEffect } from "react"
import MapContext from "./MapContext"
import BingMaps from 'ol/source/BingMaps'
import { Tile as TileLayer } from 'ol/layer'

import * as ol from "ol"

const MapProvider = ({ children }) => {
  const [map, setMap] = useState(null)

  useEffect(() => {
    const options = {
      view: new ol.View({
        center: [0, 0],
        zoom: 1
      }),
      layers: [new TileLayer({
        basemap: true,
        preload: Infinity,
        source: new BingMaps({
          key: 'AuqL7zkYB32GHmhxz9vNX57QCCSz0jirIaOx0uxv4w5AvHVDvvPyTP4kdcNctfvI',
          imagerySet: 'AerialWithLabelsOnDemand',
          maxZoom: 19
        }),
        title: 'Bing Basemap'
      })],
      controls: [],
    }
    const map = new ol.Map(options)
    map.setTarget("map")
    setMap(map)

    return () => map.setTarget(undefined)
  }, [])

  return (
    <MapContext.Provider value={{ map }}>
        {children}
    </MapContext.Provider>
  )
}
export default MapProvider