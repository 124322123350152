import React, {useContext} from "react"
import styled from "styled-components"
import { Routes, Route } from "react-router-dom"

import FacilityList from "../../panels/FacilityList"
import StatesList from "../../panels/StatesList"
import EditFacility from "../../panels/EditFacility"
import EditCourse from "../../panels/EditCourse"
import FeatureTools from "../../panels/components/FeatureTools"
import Login from "../auth/Login"
import Register from "../auth/Register"
import { getUserId } from "../../firebase"



const SidebarWrapper = styled.div`
  width: 400px;
  background: #ffffff;
  border-right: 1px solid #f0f0f0;
  height: calc(100vh - 45px);
  overflow: scroll;
`

const Sidebar = () => {
  // const initial = {
  //   props: {
  //     panelNumber: 1,
  //   },
  //   renderPanel: FacilityList,
  //   title: "Facilities",
  // }

  window.getUser = () => getUserId()

  return (
    <SidebarWrapper>
      <Routes>
        <Route path="/" exact element={<><FeatureTools /><StatesList /></>} />
        <Route path="/:state" element={<><FeatureTools /><FacilityList /></>} />
        <Route path="/edit/:facilityId" element={<><FeatureTools /><EditFacility /></>} />
        <Route path="/edit/:facilityId/:courseId" element={<><EditCourse /></>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </SidebarWrapper>
  )
}

export default Sidebar