import React, { useEffect } from "react"
import styled from "styled-components"

const NewFacilityButtion = styled.button`
   position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 100;

    background-color: #aaffaa;
    color: black;
    border: none;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
`

const DrawHeader = ({ onNewFacilityClick }) => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "n" || e.key === "r") {
                onNewFacilityClick()
            }
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [onNewFacilityClick])
    return <NewFacilityButtion onClick={onNewFacilityClick}>Shuffle Course</NewFacilityButtion>
}

export default DrawHeader
