import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithApple, signInWithGoogle } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components";

const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #009D63;
    height: 100vh;
`
const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    width: 300px;
    height: 300px;
`
const LoginTextBox = styled.input`
    border: 1px solid #009D63;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: 200px;
`
const LoginButton = styled.button`
    border: 1px solid #009D63;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: 200px;
    background: #009D63;
    color: #ffffff;
    font-weight: 600;
`
const LoginWithGoogleButton = styled.button`
    border: 1px solid #009D63;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    width: 200px;
    background: #ffffff;
    color: #009D63;
    font-weight: 600;
`


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    console.log("localStorage.getItem('previousUrl')", localStorage.getItem('previousUrl'))
    if (user) navigate(localStorage.getItem('previousUrl') || "/");
  }, [user, loading]);

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginTextBox
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <LoginTextBox
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <LoginButton
          className="login__btn"
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          Login
        </LoginButton>
        <LoginWithGoogleButton onClick={signInWithGoogle}>
          Login with Google
        </LoginWithGoogleButton>
        <LoginWithGoogleButton onClick={signInWithApple}>
          Login with Apple
        </LoginWithGoogleButton>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </LoginContainer>
    </LoginWrapper>
  );
}
export default Login;