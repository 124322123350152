import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { FeatureTypes } from "../featureTypes"
import { getUserId } from "../firebase"
import { geojsonToOl, olToGeojson } from "../utils"
import { smoothPolygon } from "./map/drawTools"
import MapContext from "./map/MapContext"
import { getLayer } from "./map/utils"

const HeaderWrapper = styled.div`
  height: 20px;
  background: #009D63;
  display: flex;
  align-items: center;
  padding: 17px;
  justify-content: space-between;
`

const HeaderTitle = styled.span`
  font-weight: 600;
  font-size: 26px;
  color: #ffffff;
`

const LinkButton = styled.input`
  border-radius: 4px;
  border: 1px solid #ffffff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  cursor: pointer;
  margin-left: 10px;
`

const Label = styled.label`
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
`

const Header = () => {
  const { map } = useContext(MapContext)
  const [geojson, setGeojson] = useState()

  useEffect(() => {
    if (geojson && map) {
      const smoothedFeatures = geojson.features.map(f => {
        let geometry = f.geometry
        if (f.geometry.type === 'Polygon') {
          geometry = olToGeojson(smoothPolygon(geojsonToOl(f))).geometry
        }
        const editedFeature = {
          ...f,
          geometry
        }
        return editedFeature
      })

      smoothedFeatures.map(async feature => {
        const { geometry } = feature

        if (geometry.type === 'Polygon') {
          const updatedFeature = {
            type: 'Feature',
            properties: {
              type: FeatureTypes.GREEN
            },
            geometry
          }

          const url = 'https://atlas-api.services.divvit.co/geometries/polygons'
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({...updatedFeature, properties: {...updatedFeature.properties, created_by: getUserId()}})
          }).then(res => res.json()).then(res => {
            getLayer(FeatureTypes.GREEN, map).getSource().addFeature(geojsonToOl(res))
          })
        } else if (geometry.type === 'LineString') {
          const updatedFeature = {
            type: 'Feature',
            properties: {
              type: FeatureTypes.HOLE
            },
            geometry
          }

          const url = 'https://atlas-api.services.divvit.co/geometries/lines'
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({...updatedFeature, properties: {...updatedFeature.properties, created_by: getUserId()}})
          }).then(res => res.json()).then(res => {
            getLayer(FeatureTypes.HOLE, map).getSource().addFeature(geojsonToOl(res))
          })
        }
      })
    }
  }, [geojson, map])


const uploadGeojson = (e) => {
  const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => setGeojson(JSON.parse(e.target.result))
}


  return (
    <HeaderWrapper>
      <HeaderTitle>Divvit Data Generator</HeaderTitle>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Label>Upload Geojson</Label>
        <LinkButton type='file' onChange={uploadGeojson}></LinkButton>
      </div>
    </HeaderWrapper>
  )
}

export default Header